import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  items: null,
  isLoading: false,
  error: null,
}

const slice = createSlice({
  name: 'codeSnippets',
  initialState,
  reducers: {
    SET_CODE_SNIPPETS_LOADING: (state, { payload }) => {
      state.isLoading = payload
    },
    CODE_SNIPPET_ITEMS_UPDATED: (state, { payload }) => {
      state.items = payload
      state.error = null
    },
    RESET_CODE_SNIPPETS_STATE: () => initialState,
    SET_CODE_SNIPPETS_ERROR: (state, { payload }) => {
      state.error = payload
      state.isLoading = false
    },
  },
})

export const {
  SET_CODE_SNIPPETS_LOADING,
  CODE_SNIPPET_ITEMS_UPDATED,
  RESET_CODE_SNIPPETS_STATE,
  SET_CODE_SNIPPETS_ERROR,
} = slice.actions
export default slice.reducer
