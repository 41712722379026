import styled from 'styled-components'
import { Modal as AntForm } from 'antd'

export const Modal = styled(AntForm)`
  max-width: 720px;
  width: 100% !important;

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 0px;

    > .content {
      width: 530px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 36px;

      .title {
        display: flex;
        gap: 10px;
        font-weight: 400;
        margin: 0px;

        .icon {
          height: 30px;
          width: 30px;
          color: ${(props) => props.theme.colors.gold5};
        }
      }

      .subtitle {
        color: ${(props) => props.theme.colors.gray7};
        line-height: 24px;
        margin: 0px;

        > p,
        > ul li {
          color: ${(props) => props.theme.colors.gray7};
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }

      .actions {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .text {
        margin: 0px;
      }
    }
  }
`
