import {
  CUSTOM_MODULE_VERSIONS_UPDATED,
  CUSTOM_MODULE_VERSION_UPDATED,
  CONTENT_JSON_UPDATED,
  CUSTOM_MODULE_MODE_UPDATED,
  INFO_SIDE_DRAWER_UPDATED,
  SET_ACTIVITY_WIZARD_MODAL_OPEN,
  SET_PUBLISH_MODAL_OPEN,
  SET_CUSTOM_MODULES_LOADING,
  SET_CUSTOM_MODULE_VERSIONS_LOADING,
  SET_CUSTOM_MODULES_PUBLISH_LOADING,
  SET_CUSTOM_MODULES_ERROR,
  RESET_CUSTOM_MODULES_STATE,
} from '@/store/customModules'
import {
  getCustomModuleVersions,
  getCustomModuleVersion,
  patchCustomModuleVersion,
  postCustomModuleVersion,
  postCustomModuleVersionEdit,
  postCustomModuleVersionStop,
  postCustomModuleVersionPublish,
} from '@/services/customModules'

const fetchCustomModuleVersions = (customModuleId) => async (dispatch) => {
  try {
    dispatch(SET_CUSTOM_MODULE_VERSIONS_LOADING(true))

    const customModuleVersions = await getCustomModuleVersions(customModuleId)
    dispatch(CUSTOM_MODULE_VERSIONS_UPDATED(customModuleVersions))
  } catch (error) {
    const { message } = error
    dispatch(SET_CUSTOM_MODULES_ERROR(message))
  } finally {
    dispatch(SET_CUSTOM_MODULE_VERSIONS_LOADING(false))
  }
}

const fetchCustomModuleVersion = (customModuleId, versionId) => async (dispatch) => {
  try {
    dispatch(SET_CUSTOM_MODULES_LOADING(true))

    const customModuleVersion = await getCustomModuleVersion(customModuleId, versionId)
    dispatch(CUSTOM_MODULE_VERSION_UPDATED(customModuleVersion))
  } catch (error) {
    const { message } = error
    dispatch(SET_CUSTOM_MODULES_ERROR(message))
  } finally {
    dispatch(SET_CUSTOM_MODULES_LOADING(false))
  }
}

const updateCustomModuleVersion = (customModuleId, versionId, data, onSuccess, onError) => async (dispatch) => {
  try {
    dispatch(SET_CUSTOM_MODULES_LOADING(true))

    const customModuleVersion = await patchCustomModuleVersion(customModuleId, versionId, data)
    dispatch(CUSTOM_MODULE_VERSION_UPDATED(customModuleVersion))

    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_CUSTOM_MODULES_ERROR(message))

    if (onError) {
      onError()
    }
  } finally {
    dispatch(SET_CUSTOM_MODULES_LOADING(false))
  }
}

const createCustomModuleVersion = (customModuleId, onSuccess, onError) => async (dispatch, getState) => {
  try {
    dispatch(SET_CUSTOM_MODULES_LOADING(true))

    const { customModuleVersions } = getState().customModules

    const customModuleVersion = await postCustomModuleVersion(customModuleId)
    const auxCustomModuleVersion = {
      ...customModuleVersion,
      justCreated: true,
    }

    dispatch(CUSTOM_MODULE_VERSION_UPDATED(auxCustomModuleVersion))

    const auxCustomModuleVersions = [...customModuleVersions, auxCustomModuleVersion]

    dispatch(CUSTOM_MODULE_VERSIONS_UPDATED(auxCustomModuleVersions))

    if (onSuccess) {
      onSuccess(customModuleVersion)
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_CUSTOM_MODULES_ERROR(message))

    if (onError) {
      onError()
    }
  } finally {
    dispatch(SET_CUSTOM_MODULES_LOADING(false))
  }
}

const createCustomModuleVersionEdit = (customModuleId, versionId, onSuccess, onError) => async (dispatch, getState) => {
  try {
    dispatch(SET_CUSTOM_MODULES_LOADING(true))

    const { userProfile } = getState().users
    const { currentCustomModuleVersion } = getState().customModules

    const res = await postCustomModuleVersionEdit(customModuleId, versionId)

    dispatch(
      CUSTOM_MODULE_VERSION_UPDATED({
        ...currentCustomModuleVersion,
        allocated_by: {
          id: userProfile.id,
          name: `${userProfile.first_name} ${userProfile.last_name}`,
        },
        allocated_session: res?.lab_session,
      }),
    )

    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_CUSTOM_MODULES_ERROR(message))

    if (onError) {
      onError(message)
    }
  } finally {
    dispatch(SET_CUSTOM_MODULES_LOADING(false))
  }
}

const createCustomModuleVersionStop =
  (customModuleId, versionId, data, onSuccess, onError) => async (dispatch, getState) => {
    try {
      dispatch(SET_CUSTOM_MODULES_LOADING(true))

      const { currentCustomModuleVersion } = getState().customModules

      await postCustomModuleVersionStop(customModuleId, versionId, data)

      dispatch(
        CUSTOM_MODULE_VERSION_UPDATED({
          ...currentCustomModuleVersion,
          allocated_by: null,
          allocated_session: null,
        }),
      )

      if (onSuccess) {
        onSuccess()
      }
    } catch (error) {
      const { message } = error
      dispatch(SET_CUSTOM_MODULES_ERROR(message))

      if (onError) {
        onError(message)
      }
    } finally {
      dispatch(SET_CUSTOM_MODULES_LOADING(false))
    }
  }

const createCustomModuleVersionPublish =
  (customModuleId, versionId, onSuccess, onError) => async (dispatch, getState) => {
    try {
      dispatch(SET_CUSTOM_MODULES_PUBLISH_LOADING(true))

      const { customModuleVersions, currentCustomModuleVersion } = getState().customModules

      await postCustomModuleVersionPublish(customModuleId, versionId)

      const auxCustomModuleVersion = {
        ...currentCustomModuleVersion,
        status: 'published',
        allocated_by: null,
        allocated_session: null,
      }

      dispatch(CUSTOM_MODULE_VERSION_UPDATED(auxCustomModuleVersion))

      const auxCustomModuleVersions = customModuleVersions?.map((version) => {
        if (version.id === versionId) {
          return auxCustomModuleVersion
        }

        return version
      })

      dispatch(CUSTOM_MODULE_VERSIONS_UPDATED(auxCustomModuleVersions))

      if (onSuccess) {
        onSuccess()
      }
    } catch (error) {
      const { message } = error
      dispatch(SET_CUSTOM_MODULES_ERROR(message))

      if (onError) {
        onError(message)
      }
    } finally {
      dispatch(SET_CUSTOM_MODULES_PUBLISH_LOADING(false))
    }
  }

const addPage = (page) => (dispatch, getState) => {
  const { contentJson } = getState().customModules

  const updatedContent = [...(contentJson || []), page]
  dispatch(CONTENT_JSON_UPDATED(updatedContent))
}

const removePage = (pageId) => (dispatch, getState) => {
  const { contentJson } = getState().customModules

  const updatedContent = contentJson.filter((p) => p.id !== pageId)
  dispatch(CONTENT_JSON_UPDATED(updatedContent))
}

const updatePage = (pageId, updates) => (dispatch, getState) => {
  const { contentJson } = getState().customModules

  const updatedContent = contentJson.map((page) => {
    if (page.id === pageId) {
      return { ...page, ...updates }
    }

    return page
  })

  dispatch(CONTENT_JSON_UPDATED(updatedContent))
}

const updatePages = (pages) => (dispatch) => {
  dispatch(CONTENT_JSON_UPDATED(pages))
}

const addPageContent = (pageId, newContent) => (dispatch, getState) => {
  const { contentJson } = getState().customModules

  const updatedContent = contentJson.map((page) => {
    if (page.id === pageId) {
      return {
        ...page,
        content: [...page.content, newContent],
      }
    }

    return page
  })

  dispatch(CONTENT_JSON_UPDATED(updatedContent))
}

const removePageContent = (pageId, contentId) => (dispatch, getState) => {
  const { contentJson } = getState().customModules

  const updatedContent = contentJson.map((page) => {
    if (page.id === pageId) {
      return {
        ...page,
        content: page.content.filter((content) => content.id !== contentId),
      }
    }

    return page
  })

  dispatch(CONTENT_JSON_UPDATED(updatedContent))
}

const updatePageContent = (pageId, contentId, updates) => (dispatch, getState) => {
  const { contentJson } = getState().customModules

  const updatedContent = contentJson.map((page) => {
    if (page.id === pageId) {
      return {
        ...page,
        content: page.content.map((content) => {
          if (content.id === contentId) {
            return { ...content, ...updates }
          }

          return content
        }),
      }
    }

    return page
  })

  dispatch(CONTENT_JSON_UPDATED(updatedContent))
}

const setCustomModuleMode = (mode) => (dispatch) => {
  dispatch(CUSTOM_MODULE_MODE_UPDATED(mode))
}

const selectInfoSideDrawerItem = (newPage) => (dispatch) => {
  dispatch(INFO_SIDE_DRAWER_UPDATED(newPage))
}

const setActivityWizardModalOpen = (isOpen) => (dispatch) => {
  dispatch(SET_ACTIVITY_WIZARD_MODAL_OPEN(isOpen))
}

const setPublishModalOpen = (isOpen) => (dispatch) => {
  dispatch(SET_PUBLISH_MODAL_OPEN(isOpen))
}

const resetCustomModulesState = () => (dispatch) => {
  dispatch(RESET_CUSTOM_MODULES_STATE())
}

export {
  fetchCustomModuleVersions,
  fetchCustomModuleVersion,
  updateCustomModuleVersion,
  createCustomModuleVersion,
  createCustomModuleVersionEdit,
  createCustomModuleVersionStop,
  createCustomModuleVersionPublish,
  addPage,
  removePage,
  updatePage,
  updatePages,
  addPageContent,
  removePageContent,
  updatePageContent,
  setCustomModuleMode,
  selectInfoSideDrawerItem,
  setActivityWizardModalOpen,
  setPublishModalOpen,
  resetCustomModulesState,
}
