import { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Eye24Regular, Edit24Regular, Warning24Regular } from '@fluentui/react-icons'
import { Tag } from 'antd'
import { Save24Regular, ArrowSquareDown24Regular } from '@fluentui/react-icons'
import { showToast } from '@/utils/toast'
import Button from '@/components/Button'
import {
  updateCustomModuleVersion,
  createCustomModuleVersionEdit,
  createCustomModuleVersionStop,
  setCustomModuleMode,
  setPublishModalOpen,
} from '@/store/customModules/actions'
import { resetLabsState } from '@/store/labs/actions'
import { Container } from './styles'

const SecondaryNavbar = () => {
  const dispatch = useDispatch()

  const { currentCustomModuleVersion, contentJson, mode, isCustomModulesLoading, isCustomModuleVersionsLoading } =
    useSelector((state) => state.customModules)

  const handleStartEditing = () => {
    if (currentCustomModuleVersion?.status === 'draft') {
      dispatch(
        createCustomModuleVersionEdit(
          currentCustomModuleVersion?.custom_module_id,
          currentCustomModuleVersion?.id,
          () => dispatch(setCustomModuleMode('edit')),
          (message) => showToast(message, 'error'),
        ),
      )
    }

    if (currentCustomModuleVersion?.status === 'published') {
      dispatch(setCustomModuleMode('amend'))
    }
  }

  const handleStopEditing = () => {
    dispatch(
      createCustomModuleVersionStop(
        currentCustomModuleVersion?.custom_module_id,
        currentCustomModuleVersion?.id,
        {
          reason: 'manual',
        },
        () => {
          dispatch(resetLabsState())
          dispatch(setCustomModuleMode('browse'))
        },
        (message) => showToast(message, 'error'),
      ),
    )
  }

  const handleCustomModuleVersionUpdate = async (isQuiet, auxContentJson) => {
    const updatedCustomModuleVersion = {
      content_json: auxContentJson || contentJson,
    }

    await dispatch(
      updateCustomModuleVersion(
        currentCustomModuleVersion?.custom_module_id,
        currentCustomModuleVersion?.id,
        updatedCustomModuleVersion,
        isQuiet ? () => {} : () => showToast('Content updated successfully!'),
        () => showToast('There was an error updating project content', 'error'),
      ),
    )
  }

  const handlePublish = () => {
    handleCustomModuleVersionUpdate(true)
    dispatch(setPublishModalOpen(true))
  }

  const renderContent = () => {
    if (mode === 'browse')
      return (
        <>
          <Eye24Regular className="icon" /> You’re browsing
        </>
      )

    if (mode === 'preview')
      return (
        <>
          <Eye24Regular className="icon" /> You’re previewing
        </>
      )

    if (mode === 'edit')
      return (
        <>
          <Edit24Regular className="icon" /> You’re editing
        </>
      )

    if (mode === 'amend')
      return (
        <>
          <Warning24Regular className="icon" /> You’re in amend mode. Any changes you make will be instantly visible
        </>
      )
  }

  const renderActions = () => {
    if (mode === 'browse' && !currentCustomModuleVersion?.allocated_by) {
      return (
        <Button
          className="button secondary-button"
          type="text"
          onClick={handleStartEditing}
          loading={isCustomModulesLoading}
        >
          <Edit24Regular className="icon" /> Start editing
        </Button>
      )
    }

    if (mode === 'edit')
      return (
        <>
          <Button
            className="button secondary-button"
            type="text"
            onClick={handleStopEditing}
            loading={isCustomModulesLoading}
          >
            Stop editing
          </Button>

          <Button
            className="button save-button"
            onClick={() => handleCustomModuleVersionUpdate()}
            loading={isCustomModulesLoading}
          >
            <Save24Regular className="icon" /> Save
          </Button>

          <Button className="button publish-button" type="secondary" onClick={handlePublish}>
            <ArrowSquareDown24Regular className="icon" /> Publish
          </Button>
        </>
      )

    if (mode === 'preview') {
      return (
        <Button className="button secondary-button" type="text" onClick={handleStartEditing}>
          <Edit24Regular className="icon" /> Amend content
        </Button>
      )
    }

    if (mode === 'amend') {
      return (
        <Button
          className="button publish-button"
          type="secondary"
          onClick={handleCustomModuleVersionUpdate}
          loading={isCustomModulesLoading}
        >
          <ArrowSquareDown24Regular className="icon" /> Save and Publish
        </Button>
      )
    }
  }

  const contentJsonRef = useRef(contentJson)

  useEffect(() => {
    contentJsonRef.current = contentJson
  }, [contentJson])

  useEffect(() => {
    let autoSaveInterval
    const shouldAutoSave = ['edit'].includes(mode)
    if (!shouldAutoSave) {
      if (autoSaveInterval) clearInterval(autoSaveInterval)
      return
    }

    autoSaveInterval = setInterval(() => {
      handleCustomModuleVersionUpdate(true, contentJsonRef.current)
    }, 30000)
    return () => clearInterval(autoSaveInterval)
  }, [mode])

  if (!mode) return null

  return (
    <Container className="custom-module-secondary-navbar-container" $mode={mode}>
      {currentCustomModuleVersion && !isCustomModuleVersionsLoading && (
        <>
          <div className="text-container">
            {renderContent()}

            <Tag className={`status-tag ${currentCustomModuleVersion?.status}`}>
              <span className="indicator" /> {currentCustomModuleVersion?.status}
            </Tag>
          </div>

          <div className="actions">{renderActions()}</div>
        </>
      )}
    </Container>
  )
}

export default SecondaryNavbar
