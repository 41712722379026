import styled, { css } from 'styled-components'
import { Modal as AntForm } from 'antd'

export const Modal = styled(AntForm)`
  ${(props) =>
    props.hasActivityType
      ? css`
          max-width: 650px;

          .ant-modal-content {
            background: linear-gradient(
              180deg,
              ${(props) => props.theme.colors.geekblue1} 0%,
              ${(props) => props.theme.colors.gray1} 100%
            );
          }
        `
      : css`
          max-width: 860px;

          .ant-modal-content {
            background: ${(props) => props.theme.colors.gray4} !important;
          }

          .ant-modal-body > .content {
            padding-bottom: 28px;
          }
        `}

  .ant-modal-content {
    padding: 36px;
  }

  .ant-modal-body {
    > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 36px;

      .type-selector-title {
        width: 330px;
        font-weight: 700;
        text-align: center;
        margin: 20px 0px 0px;
      }

      .title {
        margin: 0px;
      }

      .activity-types-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 24px;

        .activity-content {
          height: 200px;
          width: 180px;
          background-color: ${(props) => props.theme.colors.gray1};
          color: ${(props) => props.theme.colors.gray1};
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 30px 20px;
          user-select: none;
          cursor: pointer;
          transition: all 0.3s;

          &:hover {
            background-color: ${(props) => props.theme.colors.geekblue2};
          }

          .icon {
            color: ${(props) => props.theme.colors.geekblue5};
          }

          .activity-name {
            color: ${(props) => props.theme.colors.gray11};
            margin: 0px;
          }

          .activity-description {
            color: ${(props) => props.theme.colors.gray7};
            margin: 0px;
          }

          &.is-disabled {
            background-color: ${(props) => props.theme.colors.gray5};
            border: 1px solid ${(props) => props.theme.colors.gray5};
            cursor: not-allowed;

            &:hover {
              background-color: ${(props) => props.theme.colors.gray5};
            }
          }
        }
      }
    }
  }
`
