import { Container, Input as SInput } from './styles'

const Input = ({ className, label, fixedLabel, ...rest }) => {
  const value = rest?.value || rest?.defaultValue

  return (
    <Container $hasContent={value !== undefined && value !== ''} $fixedLabel={fixedLabel}>
      {label && <p className="label">{label}</p>}
      <SInput className={`dw-input ${className}`} {...rest} />
    </Container>
  )
}

export default Input
