import { useSelector, useDispatch } from 'react-redux'
import { ArrowCircleUpRight24Regular } from '@fluentui/react-icons'
import Button from '@/components/Button'
import HTMLBlock from '@/components/HTMLBlock'
import { selectInfoSideDrawerItem } from '@/store/customModules/actions'
import { Container } from './styles'

const StackCard = () => {
  const dispatch = useDispatch()

  const { currentCustomModuleVersion } = useSelector((state) => state.customModules)

  return (
    <Container className="stack-info-card">
      <div className="stack-content">
        {currentCustomModuleVersion?.stack?.image_url ? (
          <img
            className="stack-image"
            src={currentCustomModuleVersion?.stack?.image_url}
            alt={currentCustomModuleVersion?.stack?.name}
          />
        ) : (
          <span />
        )}

        <Button
          className="main-button"
          type="default"
          onClick={() => dispatch(selectInfoSideDrawerItem('description'))}
        >
          View stack info <ArrowCircleUpRight24Regular className="icon" />
        </Button>
      </div>

      <div className="title-container">
        <h5 className="title">{currentCustomModuleVersion?.stack?.name}</h5>

        {currentCustomModuleVersion?.stack?.description_html && (
          <HTMLBlock className="description" content={currentCustomModuleVersion?.stack?.description_html} />
        )}
      </div>
    </Container>
  )
}

export default StackCard
