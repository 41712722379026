import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { Switch, Collapse, Form, Select, Radio, Cascader } from 'antd'
import { EyeRegular } from '@fluentui/react-icons'
import Button from '@/components/Button'
import Input from '@/components/Input'
import CodeEditor from '@/components/CodeEditor'
import MarkdownEditor from '@/components/MarkdownEditor'
import { showToast } from '@/utils/toast'
import { addPageContent, updatePageContent, setActivityWizardModalOpen } from '@/store/customModules/actions'
import { getCodeSnippets } from '@/store/codeSnippets/actions'
import { Container } from '../styles'

const activityTypeOptions = [
  { label: 'Choose from template', value: 'template' },
  { label: 'Write source code', value: 'source-code' },
]

const codeTemplateOptions = [
  { value: 'python-raw', label: 'Execute in Device' },
  { value: 'jupyter-kernel', label: 'Execute in Notebook' },
]

const CodeValidatedActivityForm = ({ activityTypeName, icon }) => {
  const dispatch = useDispatch()

  const { isActivityWizardModalOpen, isCustomModulesLoading } = useSelector((state) => state.customModules)
  const { currentLab } = useSelector((state) => state.labs)
  const { items: codeSnippetsData } = useSelector((state) => state.codeSnippets)
  const sectionId = isActivityWizardModalOpen?.sectionId
  const activity = isActivityWizardModalOpen?.activity
  const isEditing = !!activity?.id

  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [selectedDevice, setSelectedDevice] = useState()
  const [codeTemplate, setCodeTemplate] = useState('jupyter-kernel')
  const [selectedActivityType, setSelectedActivityType] = useState('template')
  const [metadataNotebookPath, setMetadataNotebookPath] = useState('Project.ipynb')
  const [instructorCode, setInstructorCode] = useState('')
  const [selectedCodeSnippet, setSelectedCodeSnippet] = useState()
  // const [isCodeInputEnabled, setIsCodeInputEnabled] = useState(false)
  // const [codeInputLanguage, setCodeInputLanguage] = useState()
  // const [codeInputPlaceholder, setCodeInputPlaceholder] = useState('')
  const [expectedOutcome, setExpectedOutcome] = useState('')
  const [solution, setSolution] = useState('')
  const [hint, setHint] = useState('')
  const [showSolutions, setShowSolutions] = useState(false)
  const [showHints, setShowHints] = useState(false)

  const [form] = Form.useForm()

  const generateHierarchy = (data) => {
    if (!data?.length) return []

    const hierarchy = []

    const level0 = Array.from(new Set(data.map((item) => item?.category?.lvl0?.[0])))?.filter((item) => item)
    const level1 = Array.from(new Set(data.map((item) => item?.category?.lvl1?.[0])))?.filter((item) => item)
    const level2 = Array.from(new Set(data.map((item) => item?.category?.lvl2?.[0])))?.filter((item) => item)
    const level3 = Array.from(new Set(data.map((item) => item?.category?.lvl3?.[0])))?.filter((item) => item)

    // add level 0 categories
    level0.forEach((lvl0, index) => {
      const hierarchyItem = {
        value: index,
        label: lvl0,
        children: [],
      }

      // add level 1 categories
      level1.forEach((lvl1, index1) => {
        if (lvl1.includes(lvl0)) {
          hierarchyItem.children.push({
            value: index1,
            label: lvl1.split(' > ')[1],
            children: [],
          })

          // add level 2 categories
          level2.forEach((lvl2, index2) => {
            if (lvl2.includes(lvl1)) {
              hierarchyItem.children[index1].children.push({
                value: index2,
                label: lvl2.split(' > ')[2],
                children: [],
              })

              // add level 3 categories
              level3.forEach((lvl3, index3) => {
                if (lvl3.includes(lvl2)) {
                  hierarchyItem.children[index1].children[index2].children.push({
                    value: index3,
                    label: lvl3.split(' > ')[3],
                    children: [
                      // add level 3 items
                      ...data
                        ?.filter((item) => item?.category?.lvl3?.[0] === lvl3 && !item?.category?.lvl4)
                        ?.map((item) => ({
                          value: item?.id,
                          label: item?.name,
                        })),
                    ],
                  })
                }
              })

              // add level 2 items
              hierarchyItem.children[index1].children[index2].children.push(
                ...data
                  ?.filter((item) => item?.category?.lvl2?.[0] === lvl2 && !item?.category?.lvl3)
                  ?.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                  })),
              )
            }
          })

          // add level 1 items
          hierarchyItem.children[index1].children.push(
            ...data
              ?.filter((item) => item?.category?.lvl1?.[0] === lvl1 && !item?.category?.lvl2)
              ?.map((item) => ({
                value: item?.id,
                label: item?.name,
              })),
          )
        }
      })

      // add level 0 items
      hierarchyItem.children.push(
        ...data
          ?.filter((item) => item?.category?.lvl0?.[0] === lvl0 && !item?.category?.lvl1)
          ?.map((item) => ({
            value: item?.id,
            label: item?.name,
          })),
      )

      hierarchy.push(hierarchyItem)
    })

    return hierarchy
  }

  const handleGetCodeSnippets = () => {
    dispatch(getCodeSnippets())
  }

  const handleSaveActivity = async () => {
    try {
      await form.validateFields()
    } catch (error) {
      showToast('Please fill all required fields.', 'error')
      return
    }

    const activityBlock = {
      ...(isEditing ? activity : { id: uuidv4() }),
      type: 'code_validated',
      title_md: title,
      content_md: content,
      //
      device_name: selectedDevice?.name,
      ...(selectedActivityType === 'template'
        ? {
            snippet_id: selectedCodeSnippet?.id,
            variables: Object.assign(
              {},
              ...selectedCodeSnippet?.variables?.map((variable) => ({
                [variable.variable_name]: variable.value,
              })),
            ),
            metadata: Object.assign(
              {},
              ...selectedCodeSnippet?.metadata?.map((variable) => ({
                [variable.name]: variable.value,
              })),
            ),
          }
        : {
            // Write source code
            code_template: codeTemplate,
            metadata: { notebook_path: metadataNotebookPath },
            instructor_code: instructorCode,
          }),
      //
      expected_outcome_md: expectedOutcome,
      solution_md: solution,
      hint_md: hint,
      // show_solutions: showSolutions,
      // show_hints: showHints,
    }

    if (isEditing) {
      dispatch(updatePageContent(sectionId, activity?.id, activityBlock))
      showToast('Activity updated successfully!')
    } else {
      dispatch(addPageContent(sectionId, activityBlock))
      showToast('Activity created successfully!')
    }

    dispatch(setActivityWizardModalOpen(false))
  }

  useEffect(() => {
    if (!selectedCodeSnippet) return

    const updatedVariables = selectedCodeSnippet.variables.map((variable) => ({
      ...variable,
      value: variable.value !== undefined ? variable.value : variable.default,
    }))
    const updatedMetadata = selectedCodeSnippet.metadata.map((variable) => ({
      ...variable,
      value: variable.value !== undefined ? variable.value : variable.default,
    }))

    setSelectedCodeSnippet({ ...selectedCodeSnippet, variables: updatedVariables, metadata: updatedMetadata })

    // update form values
    form.setFieldsValue({
      Template: selectedCodeSnippet,
      ...(selectedCodeSnippet?.variables
        ? Object.assign(
            {},
            ...selectedCodeSnippet?.variables?.map((variable) => ({
              [`variable:${variable.variable_name}`]: variable.value !== undefined ? variable.value : variable.default,
            })),
          )
        : {}),
      ...(selectedCodeSnippet?.metadata
        ? Object.assign(
            {},
            ...selectedCodeSnippet?.metadata?.map((variable) => ({
              [`metadata:${variable.name}`]: variable.value !== undefined ? variable.value : variable.default,
            })),
          )
        : {}),
    })
  }, [selectedCodeSnippet?.name])

  useEffect(() => {
    if (!activity) return

    setSelectedActivityType(activity?.snippet_id ? 'template' : 'source-code')

    setTitle(activity?.title_md)
    setContent(activity?.content_md)
    setSelectedDevice(currentLab?.devices.find((device) => device.name === activity?.device_name))

    if (activity?.snippet_id) {
      const auxCodeSnippet = codeSnippetsData?.find((snippet) => snippet.id === activity?.snippet_id)

      const variables = auxCodeSnippet?.variables?.map((variable) => {
        const auxVariable = {
          ...variable,
          value: activity?.variables[variable.variable_name],
        }

        return auxVariable
      })

      const metadata = auxCodeSnippet?.metadata?.map((variable) => {
        const auxVariable = {
          ...variable,
          value: activity?.metadata[variable.name],
        }

        return auxVariable
      })

      setSelectedCodeSnippet({ ...auxCodeSnippet, variables, metadata })
    } else {
      setCodeTemplate(activity?.code_template)
      setMetadataNotebookPath(activity?.metadata?.notebook_path)
      setInstructorCode(activity?.instructor_code)

      // update form values
      form.setFieldsValue({
        code_template: activity?.code_template,
        notebook_path: activity?.metadata?.notebook_path,
        instructor_code: activity?.instructor_code,
      })
    }

    setExpectedOutcome(activity?.expected_outcome_md)
    setSolution(activity?.solution_md)
    setHint(activity?.hint_md)
    setShowSolutions(activity?.show_solutions)
    setShowHints(activity?.show_hints)

    // update form values
    form.setFieldsValue({
      Title: activity?.title_md,
    })
  }, [activity, codeSnippetsData])

  useEffect(() => {
    if (!selectedDevice && currentLab?.devices?.length) {
      setSelectedDevice(currentLab?.devices[0])
    }

    handleGetCodeSnippets()
  }, [])

  return (
    <Container className="code-validated-activity-form">
      <div className="header">
        <div className="icon-container">{icon}</div>
        <h4 className="title">{isEditing ? 'Editing' : activityTypeName} activity</h4>
      </div>

      <Form className="activity-form" form={form} name="activity-form">
        <div className="activity-form-container">
          <Form.Item name="Title" initialValue={activity?.title_md} rules={[{ required: true }]}>
            <Input
              className="activity-title"
              label="Activity title"
              value={title}
              placeholder="Activity title"
              size="large"
              onChange={(evt) => setTitle(evt.target.value)}
            />
          </Form.Item>

          <MarkdownEditor
            label="Activity description and instructions"
            content={content}
            placeholder="Activity description and instructions"
            onChange={setContent}
          />
          <hr />

          {currentLab?.devices?.length >= 2 && (
            <>
              <p className="text">Choose device:</p>
              <Select
                value={selectedDevice}
                onChange={setSelectedDevice}
                options={currentLab?.devices?.map((d) => ({
                  value: d.id,
                  label: d.name,
                }))}
              />
            </>
          )}

          <p className="text bold">Validation to perform:</p>
          <Radio.Group
            className="activity-type-selector"
            options={activityTypeOptions}
            optionType="button"
            value={selectedActivityType}
            onChange={(evt) => setSelectedActivityType(evt.target.value)}
            size="large"
            block
            name="activity-type"
          />

          {selectedActivityType === 'template' ? (
            <>
              <Form.Item name="Template" rules={[{ required: true }]}>
                <Cascader
                  className="code-snippet-cascader"
                  name="code-snippet-cascader"
                  options={generateHierarchy(codeSnippetsData)}
                  value={selectedCodeSnippet}
                  onChange={(value) => {
                    if (!value) setSelectedCodeSnippet(null)

                    const codeSnippet = codeSnippetsData?.find((item) => item.id === value[value?.length - 1])
                    setSelectedCodeSnippet(codeSnippet)
                  }}
                  placeholder="Select a predefined template"
                  displayRender={() => selectedCodeSnippet?.name}
                  showSearch={{
                    filter: (inputValue, path) =>
                      path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1),
                  }}
                />
              </Form.Item>

              {selectedCodeSnippet?.description && (
                <p className="text code-snippet-description">{selectedCodeSnippet?.description}</p>
              )}

              {!!selectedCodeSnippet?.variables?.length && <p className="text bold">Variables:</p>}
              {selectedCodeSnippet?.variables?.map((variable, index) => (
                <Form.Item
                  key={variable?.name}
                  name={`variable:${variable?.variable_name}`}
                  rules={[{ required: variable?.required, message: 'This field is required.' }]}
                >
                  <Input
                    label={variable?.description}
                    fixedLabel
                    placeholder={`${variable?.variable_name}${variable?.default !== undefined ? `. Default: ${variable?.default}` : ''}`}
                    value={variable?.value}
                    defaultValue={variable?.default}
                    onChange={(evt) => {
                      const updatedVariables = selectedCodeSnippet.variables.map((v) =>
                        v.variable_name === variable?.variable_name ? { ...v, value: evt.target.value } : v,
                      )

                      setSelectedCodeSnippet({ ...selectedCodeSnippet, variables: updatedVariables })
                    }}
                  />
                </Form.Item>
              ))}

              {!!selectedCodeSnippet?.metadata?.length && <p className="text bold">Metadata:</p>}
              {selectedCodeSnippet?.metadata?.map((variable, index) => (
                <Form.Item
                  key={variable?.name}
                  name={`metadata:${variable?.name}`}
                  rules={[{ required: variable?.required, message: 'This field is required.' }]}
                >
                  <Input
                    label={variable?.name}
                    fixedLabel
                    placeholder={`${variable?.name}${variable?.default !== undefined ? `. Default: ${variable?.default}` : ''}`}
                    value={variable?.value}
                    defaultValue={variable?.default}
                    onChange={(evt) => {
                      const updatedMetadata = selectedCodeSnippet.metadata.map((v) =>
                        v.name === variable?.name ? { ...v, value: evt.target.value } : v,
                      )

                      setSelectedCodeSnippet({ ...selectedCodeSnippet, metadata: updatedMetadata })
                    }}
                  />
                </Form.Item>
              ))}

              {/* <p className="text">Code input:</p>

            <div className="code-input-options">
              <div className="switch-box">
                <p className="text">Enabled?</p>
                <Switch value={isCodeInputEnabled} onChange={setIsCodeInputEnabled} />
              </div>

              {isCodeInputEnabled && (
                <>
                  <Select
                    className="code-input-language-select"
                    placeholder="Select code language"
                    value={codeInputLanguage}
                    onChange={setCodeInputLanguage}
                    options={[
                      { value: 'python', label: 'Python' },
                      { value: 'r', label: 'R' },
                      { value: 'sql', label: 'SQL' },
                      { value: 'shell', label: 'Shell' },
                      { value: 'julia', label: 'Julia' },
                    ]}
                  />

                  <CodeEditor
                    language={codeInputLanguage}
                    value={codeInputPlaceholder}
                    setValue={setCodeInputPlaceholder}
                    placeholder={'Write placeholder code here...'}
                  />
                </>
              )}
            </div> */}
            </>
          ) : (
            <>
              <Select
                className="code-input-code-template"
                placeholder="Where to execute?"
                value={codeTemplate}
                onChange={setCodeTemplate}
                options={codeTemplateOptions}
              />

              {codeTemplate === 'jupyter-kernel' && (
                <Form.Item
                  name="notebook_path"
                  initialValue="Project.ipynb"
                  value={metadataNotebookPath}
                  rules={[{ required: true }]}
                >
                  <Input
                    label="Notebook path"
                    placeholder="Notebook path. Default: Project.ipynb"
                    onChange={(evt) => setMetadataNotebookPath(evt.target.value)}
                  />
                </Form.Item>
              )}

              <Form.Item
                name="instructor_code"
                value={instructorCode}
                rules={[
                  {
                    validator: () => {
                      if (!instructorCode) {
                        return Promise.reject('Instructor code is required.')
                      }
                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <CodeEditor
                  language={'python'}
                  value={instructorCode}
                  setValue={setInstructorCode}
                  placeholder={'Write your validation code here...'}
                />
              </Form.Item>
            </>
          )}

          <hr />
          <Collapse
            className="advanced-options-collapse"
            ghost
            expandIconPosition="end"
            items={[
              {
                key: '1',
                label: 'Show advanced options',
                children: (
                  <div className="advanced-options-container">
                    <MarkdownEditor
                      label="Expected outcome"
                      content={expectedOutcome}
                      placeholder="Expected outcome"
                      onChange={setExpectedOutcome}
                    />
                    <MarkdownEditor label="Solution" content={solution} placeholder="Solution" onChange={setSolution} />
                    <MarkdownEditor label="Hints" content={hint} placeholder="Hints" onChange={setHint} />

                    {/* <div className="options-container">
                      <div className="option-box">
                        <div className="content">
                          <div className="info">
                            <EyeRegular className="icon" /> Show solution
                          </div>
                          <Switch checked={showSolutions} onChange={setShowSolutions} />
                        </div>
                      </div>

                      <div className="option-box">
                        <div className="content">
                          <div className="info">
                            <EyeRegular className="icon" /> Show hints
                          </div>
                          <Switch checked={showHints} onChange={setShowHints} />
                        </div>
                      </div>
                    </div> */}
                  </div>
                ),
              },
            ]}
          />
        </div>

        <div className="actions">
          <Button type="default" onClick={() => dispatch(setActivityWizardModalOpen(false))}>
            Cancel
          </Button>

          <Button type="primary" htmlType="submit" onClick={handleSaveActivity} loading={isCustomModulesLoading}>
            {isEditing ? 'Update' : 'Create'}
          </Button>
        </div>
      </Form>
    </Container>
  )
}

export default CodeValidatedActivityForm
