import { useSelector, useDispatch } from 'react-redux'
import { Spin } from 'antd'
import { setViewIsLoaded } from '@/store/labs/actions'
import { Container } from './styles'

const DeviceIframe = ({ url, title }) => {
  const dispatch = useDispatch()

  const { currentModule } = useSelector((state) => state.modules)
  const { currentPlayground } = useSelector((state) => state.playgrounds)
  const { currentCustomModuleVersion } = useSelector((state) => state.customModules)
  const { currentViewIsLoaded } = useSelector((state) => state.labs)

  const isAssessment = currentModule?.type === 'assessment'
  const isCustomProject = !!currentPlayground?.metadata?.account_id || !!currentCustomModuleVersion

  return (
    <Container id="device-iframe-container">
      {!currentViewIsLoaded && (
        <div className="loading-container">
          <Spin size="large" />
          <h3 className="title">
            Loading {isAssessment ? '' : currentModule || isCustomProject ? 'project' : 'playground'} content
          </h3>
        </div>
      )}

      <iframe id="lab-iframe" key={url} src={url} onLoad={() => dispatch(setViewIsLoaded(true))} title={title} />
    </Container>
  )
}

export default DeviceIframe
