import { algoliasearch } from 'algoliasearch'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const client = algoliasearch(
  import.meta.env.REACT_APP_ALGOLIA_APP_ID,
  import.meta.env.REACT_APP_ALGOLIA_SEARCH_ONLY_KEY,
)

const fetchAlgoliaCodeSnippets = async (filter) => {
  return client
    .searchSingleIndex({
      indexName: import.meta.env.REACT_APP_ALGOLIA_CODE_SNIPPETS_INDEX,
      // FIXME: This is a workaround to fetch all the data from Algolia
      searchParams: { query: '', filters: filter, hitsPerPage: 1000 },
    })
    .then((response) => {
      const auxData = response?.hits?.map((item) => ({
        ...item,
        id: item?.objectID,
      }))

      return Promise.resolve(auxData)
    })
    .catch((error) => Promise.reject(apiErrorHandler(error)))
}

export { fetchAlgoliaCodeSnippets }
