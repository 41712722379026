import styled from 'styled-components'

export const Container = styled.div`
  &.input-activity-form,
  &.multiple-choice-activity-form,
  &.code-validated-activity-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    > .header {
      display: flex;
      align-items: center;
      gap: 10px;

      .icon-container {
        height: 38px;
        width: 38px;
        background-color: ${(props) => props.theme.colors.geekblue4};
        color: ${(props) => props.theme.colors.gray1};
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
      }

      .title {
        margin: 0px;
      }
    }

    > .activity-form {
      display: flex;
      flex-direction: column;
      gap: 30px;

      .activity-form-container {
        max-height: calc(100vh - 320px);
        overflow: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        hr {
          width: 100%;
          border-top: 1px solid ${(props) => props.theme.colors.gray5};
        }

        .text {
          color: ${(props) => props.theme.colors.gray7};
          margin: 0px;

          &.bold {
            font-weight: 500;
          }
        }

        .activity-type-selector {
          .ant-radio-button-wrapper {
            height: 50px;
            font-size: 14px;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .code-snippet-cascader {
          width: 100%;
          margin: 0px;
        }

        .code-snippet-description {
          font-size: 12px;
          margin: -6px 0px 20px;
        }

        .code-input-options {
          display: flex;
          flex-direction: column;
          gap: 12px;
          padding-left: 12px;

          .switch-box {
            display: flex;
            align-items: center;
            gap: 6px;

            > p {
              color: ${(props) => props.theme.colors.gray7};
              margin: 0px;
            }
          }

          .code-input-language-select {
            width: 100%;
          }
        }

        .option-box {
          width: 100%;
          background-color: ${(props) => props.theme.colors.geekblue1};

          > .content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;

            .info {
              color: ${(props) => props.theme.colors.gray13};
              display: flex;
              align-items: center;
              gap: 6px;

              .icon {
                height: 20px;
                width: 20px;
              }
            }
          }
        }

        .options-container {
          display: flex;
          gap: 12px;
        }

        .answers-container {
          display: flex;
          flex-direction: column;
          gap: 12px;

          .info {
            display: flex;
            justify-content: space-between;
            gap: 10px;

            > p {
              color: ${(props) => props.theme.colors.gray7};
            }
          }

          .answer-box {
            display: flex;
            gap: 10px;

            > .editor-container {
              width: 80%;
            }

            .actions {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 6px;
              flex: 1;

              .icon-container {
                width: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-right: 1px solid ${(props) => props.theme.colors.gray4};
                margin: 0px;
                cursor: pointer;
                user-select: none;

                &:last-child {
                  border: none;
                  padding-right: 0px;
                }

                svg {
                  font-size: 18px;
                  transition: all 0.3s;
                }

                .drag-button {
                  background-color: transparent;
                }

                &:hover svg {
                  color: ${(props) => props.theme.colors.red4};
                }
              }
            }
          }

          .add-button {
            background-color: ${(props) => props.theme.colors.gray1};
          }
        }

        .advanced-options-collapse {
          background-color: ${(props) => props.theme.colors.geekblue2};
          border-radius: 4px;

          .ant-collapse-item {
            .ant-collapse-header {
              padding: 20px;

              .ant-collapse-expand-icon {
                svg {
                  height: 18px;
                  width: 18px;
                }
              }
            }

            .ant-collapse-content-box  {
              padding: 20px;
            }

            .advanced-options-container {
              display: flex;
              flex-direction: column;
              gap: 12px;
            }
          }
        }
      }

      > .actions {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
      }
    }
  }
`
