import { useSelector, useDispatch } from 'react-redux'
import { Warning24Filled } from '@fluentui/react-icons'
import { showToast } from '@/utils/toast'
import Button from '@/components/Button'
import { createCustomModuleVersionPublish, setPublishModalOpen } from '@/store/customModules/actions'
import { resetLabsState } from '@/store/labs/actions'
import { Modal } from './styles'

const PublishModal = () => {
  const dispatch = useDispatch()

  const { currentCustomModuleVersion, isPublishModalOpen, isCustomModulesLoading, isCustomModulesPublishLoading } =
    useSelector((state) => state.customModules)

  const handleClose = () => {
    dispatch(setPublishModalOpen(false))
  }

  const handlePublish = () => {
    dispatch(
      createCustomModuleVersionPublish(
        currentCustomModuleVersion?.custom_module_id,
        currentCustomModuleVersion?.id,
        () => {
          handleClose()
          dispatch(resetLabsState())
          showToast(
            `Congrats! '${currentCustomModuleVersion?.name}' V${currentCustomModuleVersion?.version_number || '1'} has been published!`,
          )
        },
        (message) => showToast(message, 'error'),
      ),
    )
  }

  return (
    <Modal
      className="custom-project-publish-modal"
      open={!!isPublishModalOpen}
      onCancel={isCustomModulesPublishLoading ? null : handleClose}
      footer={null}
      keyboard={false}
      maskClosable={false}
    >
      <div className="content">
        <h3 className="title">
          <Warning24Filled className="icon" />
          You're about to publish V{currentCustomModuleVersion?.version_number || '1'} of '
          {currentCustomModuleVersion?.name}'
        </h3>

        <h5 className="subtitle">
          <p>Once published:</p>

          <ul>
            <li>You won't be able to start the interactive lab again</li>
            <li>You won't be able to add/remove activities or sections</li>
          </ul>

          <p>You'll be able only to amend texts within the created sections and/or activities.</p>

          <p>
            To change the content of your interactive lab or add/remove sections/activities, you'll need to create a new
            version.
          </p>

          <p>Are you sure?</p>
        </h5>

        <div className="actions">
          {isCustomModulesPublishLoading ? (
            <p className="text">This will take a few seconds...</p>
          ) : (
            <Button type="default" onClick={handleClose} loading={isCustomModulesPublishLoading}>
              Cancel
            </Button>
          )}

          <Button
            type="primary"
            onClick={handlePublish}
            loading={isCustomModulesLoading || isCustomModulesPublishLoading}
          >
            Yes, publish
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default PublishModal
