import {
  SET_CODE_SNIPPETS_LOADING,
  CODE_SNIPPET_ITEMS_UPDATED,
  RESET_CODE_SNIPPETS_STATE,
  SET_CODE_SNIPPETS_ERROR,
} from '@/store/codeSnippets'
import { fetchAlgoliaCodeSnippets } from '@/services/codeSnippets'

const getCodeSnippets = () => async (dispatch) => {
  try {
    dispatch(SET_CODE_SNIPPETS_LOADING(true))

    const codeSnippets = await fetchAlgoliaCodeSnippets()
    dispatch(CODE_SNIPPET_ITEMS_UPDATED(codeSnippets))
  } catch (error) {
    const { message } = error
    dispatch(SET_CODE_SNIPPETS_ERROR(message))
  } finally {
    dispatch(SET_CODE_SNIPPETS_LOADING(false))
  }
}

const resetCodeSnippetsState = () => (dispatch) => {
  dispatch(RESET_CODE_SNIPPETS_STATE())
}

export { getCodeSnippets, resetCodeSnippetsState }
