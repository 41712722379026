import styled from 'styled-components'

export const MenuContainer = styled.div`
  max-width: 210px;
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray2 : props.theme.colors.gray1};
  position: relative;
  box-shadow: 0px 2px 8px 0px
    ${(props) => (localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray7 : props.theme.colors.gray13)}26;
  z-index: 102;

  .ant-menu {
    border-inline-end: none !important;

    &:before {
      display: none;
    }

    .ant-menu-item {
      .ant-menu-item-icon {
        path {
          fill: ${(props) => props.theme.colors.gray7};
        }
      }

      &.ant-menu-item-selected {
        background-color: ${(props) => props.theme.colors.gray4};

        .ant-menu-item-icon {
          path {
            fill: ${(props) =>
              localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray13 : props.theme.colors.gray13};
          }
        }
      }

      &.ant-menu-item-disabled {
        .ant-menu-item-icon {
          path {
            fill: ${(props) => props.theme.colors.disabledText};
          }
        }
      }

      /* &.is-browsing {
        background-color: ${(props) => props.theme.colors.geekblue1};

        &.ant-menu-item-selected,
        &:hover {
          background-color: ${(props) => props.theme.colors.geekblue3};
        }
      }

      &.is-previewing {
        background-color: ${(props) => props.theme.colors.purple1};

        &.ant-menu-item-selected,
        &:hover {
          background-color: ${(props) => props.theme.colors.purple3};
        }
      }

      &.is-editing {
        background-color: ${(props) => props.theme.colors.cyan1};

        &.ant-menu-item-selected,
        &:hover {
          background-color: ${(props) => props.theme.colors.cyan3};
        }
      }

      &.is-amending {
        background-color: ${(props) => props.theme.colors.gold1};

        &.ant-menu-item-selected,
        &:hover {
          background-color: ${(props) => props.theme.colors.gold3};
        }
      } */
    }
  }
`
