import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  customModuleVersions: null,
  currentCustomModuleVersion: null,
  contentJson: null,
  mode: null,
  infoSideDrawer: null,
  isActivityWizardModalOpen: false,
  isPublishModalOpen: false,
  isCustomModulesLoading: false,
  isCustomModuleVersionsLoading: false,
  isCustomModulesPublishLoading: false,
  error: null,
}

const slice = createSlice({
  name: 'customModules',
  initialState,
  reducers: {
    CUSTOM_MODULE_VERSIONS_UPDATED: (state, { payload }) => {
      state.customModuleVersions = payload
    },
    CUSTOM_MODULE_VERSION_UPDATED: (state, { payload }) => {
      state.currentCustomModuleVersion = payload
      state.contentJson = payload?.content_json || []
      state.error = null
    },
    CONTENT_JSON_UPDATED: (state, { payload }) => {
      state.contentJson = payload
    },
    CUSTOM_MODULE_MODE_UPDATED: (state, { payload }) => {
      state.mode = payload
    },
    INFO_SIDE_DRAWER_UPDATED: (state, { payload }) => {
      state.infoSideDrawer = payload
    },
    SET_ACTIVITY_WIZARD_MODAL_OPEN: (state, { payload }) => {
      state.isActivityWizardModalOpen = payload
    },
    SET_PUBLISH_MODAL_OPEN: (state, { payload }) => {
      state.isPublishModalOpen = payload
    },
    SET_CUSTOM_MODULES_LOADING: (state, { payload }) => {
      state.isCustomModulesLoading = payload
    },
    SET_CUSTOM_MODULE_VERSIONS_LOADING: (state, { payload }) => {
      state.isCustomModuleVersionsLoading = payload
    },
    SET_CUSTOM_MODULES_PUBLISH_LOADING: (state, { payload }) => {
      state.isCustomModulesPublishLoading = payload
    },
    SET_CUSTOM_MODULES_ERROR: (state, { payload }) => {
      state.error = payload
      state.isLoading = false
    },
    RESET_CUSTOM_MODULES_STATE: () => initialState,
  },
})

export const {
  CUSTOM_MODULE_VERSIONS_UPDATED,
  CUSTOM_MODULE_VERSION_UPDATED,
  CONTENT_JSON_UPDATED,
  CUSTOM_MODULE_MODE_UPDATED,
  INFO_SIDE_DRAWER_UPDATED,
  SET_ACTIVITY_WIZARD_MODAL_OPEN,
  SET_PUBLISH_MODAL_OPEN,
  SET_CUSTOM_MODULES_LOADING,
  SET_CUSTOM_MODULE_VERSIONS_LOADING,
  SET_CUSTOM_MODULES_PUBLISH_LOADING,
  SET_CUSTOM_MODULES_ERROR,
  RESET_CUSTOM_MODULES_STATE,
} = slice.actions
export default slice.reducer
