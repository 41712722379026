import { message } from 'antd'

export const showToast = (content, type, duration = 4) => {
  message[type || 'success']({
    content,
    duration,
    style: { textAlign: 'right', marginTop: '0vh' },
    onClick: () => {
      message.destroy()
    },
  })
}
