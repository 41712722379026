import { configureStore } from '@reduxjs/toolkit'
import { createReduxEnhancer } from '@sentry/react'
import accountsReducer from './accounts'
import activitiesReducer from './activities'
import appReducer from './app'
import codeSnippetsReducer from './codeSnippets'
import customModulesReducer from './customModules'
import customProjectsReducer from './customProjects'
import dataSourcesReducer from './dataSources'
import gptReducer from './gpt'
import invitesReducer from './invites'
import issuesReducer from './issues'
import jumpboxesReducer from './jumpboxes'
import labsReducer from './labs'
import launchReducer from './launch'
import learningAreasReducer from './learningAreas'
import modulesReducer from './modules'
import playgroundsReducer from './playgrounds'
import skillTracksReducer from './skillTracks'
import usersReducer from './users'
import vouchersReducer from './vouchers'

const reducer = {
  accounts: accountsReducer,
  activities: activitiesReducer,
  app: appReducer,
  codeSnippets: codeSnippetsReducer,
  customModules: customModulesReducer,
  customProjects: customProjectsReducer,
  dataSources: dataSourcesReducer,
  gpt: gptReducer,
  invites: invitesReducer,
  issues: issuesReducer,
  jumpboxes: jumpboxesReducer,
  labs: labsReducer,
  launch: launchReducer,
  learningAreas: learningAreasReducer,
  modules: modulesReducer,
  playgrounds: playgroundsReducer,
  skillTracks: skillTracksReducer,
  users: usersReducer,
  vouchers: vouchersReducer,
}

const preloadedState = {}

const sentryReduxEnhancer = createReduxEnhancer({
  configureScopeWithState(scope, state) {
    const { users } = state
    const userProfile = users?.userProfile

    if (userProfile) {
      scope.setUser({
        id: userProfile.id,
      })
      // scope.setExtras(extras)
    }
  },
})

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => {
    return [...getDefaultMiddleware()]
  },
  devTools: import.meta.env.REACT_APP_ENVIRONMENT !== 'production',
  preloadedState,
  enhancers: (getDefaultEnhancers) => {
    return [...getDefaultEnhancers(), sentryReduxEnhancer]
  },
})

export default store
