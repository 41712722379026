import { useSelector } from 'react-redux'
import {
  Battery224Regular,
  Battery524Regular,
  Battery824Regular,
  Rocket24Regular,
  DeveloperBoard24Regular,
  Storage24Regular,
} from '@fluentui/react-icons'
import { capitalize } from '@/utils/functions'
import HTMLBlock from '@/components/HTMLBlock'
import { Container } from './styles'

const DescriptionDrawerItem = () => {
  const { currentCustomModuleVersion } = useSelector((state) => state.customModules)

  return (
    <Container className="description-drawer-item">
      <div className="content">
        {currentCustomModuleVersion?.stack && (
          <div className="card stack-card">
            <div className="image-container">
              <img
                className="image"
                src={currentCustomModuleVersion?.stack?.image_url}
                alt={currentCustomModuleVersion?.stack?.name}
              />
            </div>

            <div className="title-container">
              <h5 className="title">{currentCustomModuleVersion?.stack?.name}</h5>
              <HTMLBlock content={currentCustomModuleVersion?.stack?.description_html} className="description" />
            </div>
          </div>
        )}

        {currentCustomModuleVersion?.stack_size && (
          <div className="card box-size-card">
            <div className="header">
              <div className="image-container">
                {currentCustomModuleVersion?.stack_size?.name === 'small' && <Battery224Regular />}
                {currentCustomModuleVersion?.stack_size?.name === 'medium' && <Battery524Regular />}
                {currentCustomModuleVersion?.stack_size?.name === 'large' && <Battery824Regular />}
              </div>

              <h5 className="name">{capitalize(currentCustomModuleVersion?.stack_size?.name)}</h5>
            </div>

            <div className="value-boxes-container">
              <div className="value-box">
                <p className="key">
                  <Rocket24Regular className="icon" />
                  RAM
                </p>

                <p className="value">{currentCustomModuleVersion?.stack_size?.ram?.human}</p>
              </div>

              <div className="value-box">
                <p className="key">
                  <DeveloperBoard24Regular className="icon" />
                  CPU
                </p>

                <p className="value">{currentCustomModuleVersion?.stack_size?.cpu?.human}</p>
              </div>

              <div className="value-box">
                <p className="key">
                  <Storage24Regular className="icon" />
                  Storage
                </p>

                <p className="value">{currentCustomModuleVersion?.stack_size?.storage?.human}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}

export default DescriptionDrawerItem
