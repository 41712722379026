import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.gray1};
  display: flex;
  flex-direction: column;

  > .header {
    border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
    padding: 16px 24px;

    .title {
      font-weight: 500;
      margin: 0px;
    }
  }

  > .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    overflow: auto;
    padding: 24px 0px;

    .versions-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .no-content {
        max-width: 260px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 48px 16px 16px;

        .image {
          width: 120px;
        }

        .text {
          color: ${(props) => props.theme.colors.gray7};
          font-weight: 400;
          text-align: center;
        }
      }

      .version-item {
        width: 100%;
        display: flex;
        gap: 16px;
        border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
        padding: 20px 24px;

        &:hover {
          background-color: ${(props) => props.theme.colors.gray2};
        }

        .version-code {
          min-width: 40px;
          height: 40px;
          background-color: ${(props) => props.theme.colors.cyan2};
          color: ${(props) => props.theme.colors.cyan6};
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          text-transform: uppercase;
          border-radius: 50%;
          margin: 0px;
          user-select: none;
        }

        .version-data {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 2px;

          .info {
            display: flex;
            align-items: center;
            gap: 8px;
            margin: 0px;

            .date {
            }

            .status-tag {
            }
          }

          .description {
            color: ${(props) => props.theme.colors.gray7};
            font-size: 12px;
            font-weight: 20px;
            margin: 0px;
          }
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .action-buttons {
        display: flex;
        justify-content: flex-end;
        gap: 14px;
        padding-top: 6px;

        .icon {
          height: 32px;
          width: 32px;
          color: ${(props) => props.theme.colors.gray7};
        }
      }

      .is-current-version {
        background-color: ${(props) => props.theme.colors.gray4};
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: 12px;
        border-radius: 8px;
        white-space: nowrap;
        padding: 4px 6px;

        .icon {
          color: ${(props) => props.theme.colors.green5};
          font-size: 20px;
        }
      }
    }
  }
`
