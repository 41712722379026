import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { Switch, Collapse, Form } from 'antd'
import { EyeRegular } from '@fluentui/react-icons'
import Button from '@/components/Button'
import Input from '@/components/Input'
import MarkdownEditor from '@/components/MarkdownEditor'
import { showToast } from '@/utils/toast'
import { addPageContent, updatePageContent, setActivityWizardModalOpen } from '@/store/customModules/actions'
import { Container } from '../styles'

const InputActivityForm = ({ activityTypeName, icon }) => {
  const dispatch = useDispatch()

  const { isActivityWizardModalOpen, isCustomModulesLoading } = useSelector((state) => state.customModules)
  const sectionId = isActivityWizardModalOpen?.sectionId
  const activity = isActivityWizardModalOpen?.activity
  const isEditing = !!activity?.id

  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [correctAnswer, setCorrectAnswer] = useState('')
  const [isCaseSensitive, setIsCaseSensitive] = useState(false)
  const [solution, setSolution] = useState('')
  const [hint, setHint] = useState('')
  const [showSolutions, setShowSolutions] = useState(false)
  const [showHints, setShowHints] = useState(false)

  const [form] = Form.useForm()

  const handleSaveActivity = async () => {
    try {
      await form.validateFields()
    } catch (error) {
      showToast('Please fill all required fields.', 'error')
      return
    }

    const activityBlock = {
      ...(isEditing ? activity : { id: uuidv4() }),
      type: 'input',
      title_md: title,
      content_md: content,
      //
      correct_answer: correctAnswer,
      case_sensitive: isCaseSensitive,
      //
      expected_outcome_md: '',
      solution_md: solution,
      hint_md: hint,
      // show_solutions: showSolutions,
      // show_hints: showHints,
    }

    if (isEditing) {
      dispatch(updatePageContent(sectionId, activity?.id, activityBlock))
      showToast('Activity updated successfully!')
    } else {
      dispatch(addPageContent(sectionId, activityBlock))
      showToast('Activity created successfully!')
    }

    dispatch(setActivityWizardModalOpen(false))
  }

  useEffect(() => {
    if (!activity) return

    setTitle(activity?.title_md)
    setContent(activity?.content_md)
    setCorrectAnswer(activity?.correct_answer)
    setIsCaseSensitive(activity?.case_sensitive)
    setSolution(activity?.solution_md)
    setHint(activity?.hint_md)
    setShowSolutions(activity?.show_solutions)
    setShowHints(activity?.show_hints)

    form.setFieldsValue({
      Title: activity?.title_md,
      'Correct answer': activity?.correct_answer,
    })
  }, [activity])

  return (
    <Container className="input-activity-form">
      <div className="header">
        <div className="icon-container">{icon}</div>
        <h4 className="title">{isEditing ? 'Editing' : activityTypeName} activity</h4>
      </div>

      <Form className="activity-form" form={form} name="activity-form">
        <div className="activity-form-container">
          <Form.Item name="Title" initialValue={activity?.title_md} rules={[{ required: true }]}>
            <Input
              className="activity-title"
              label="Activity title"
              value={title}
              placeholder="Activity title"
              size="large"
              onChange={(evt) => setTitle(evt.target.value)}
            />
          </Form.Item>

          <MarkdownEditor
            label="Activity description and instructions"
            content={content}
            placeholder="Activity description and instructions"
            onChange={setContent}
          />

          <hr />

          <Form.Item name="Correct answer" initialValue={activity?.correct_answer} rules={[{ required: true }]}>
            <Input
              label="Correct answer"
              value={correctAnswer}
              placeholder="Correct answer"
              onChange={(evt) => setCorrectAnswer(evt.target.value)}
            />
          </Form.Item>

          <div className="option-box">
            <div className="content">
              <div className="info">Is case sensitive?</div>
              <Switch checked={isCaseSensitive} onChange={setIsCaseSensitive} />
            </div>
          </div>

          <hr />

          <Collapse
            className="advanced-options-collapse"
            ghost
            expandIconPosition="end"
            items={[
              {
                key: '1',
                label: 'Show advanced options',
                children: (
                  <div className="advanced-options-container">
                    <MarkdownEditor label="Solution" content={solution} placeholder="Solution" onChange={setSolution} />
                    <MarkdownEditor label="Hints" content={hint} placeholder="Hints" onChange={setHint} />

                    {/* <div className="options-container">
                      <div className="option-box">
                        <div className="content">
                          <div className="info">
                            <EyeRegular className="icon" /> Show solution
                          </div>
                          <Switch checked={showSolutions} onChange={setShowSolutions} />
                        </div>
                      </div>

                      <div className="option-box">
                        <div className="content">
                          <div className="info">
                            <EyeRegular className="icon" /> Show hints
                          </div>
                          <Switch checked={showHints} onChange={setShowHints} />
                        </div>
                      </div>
                    </div> */}
                  </div>
                ),
              },
            ]}
          />
        </div>

        <div className="actions">
          <Button type="default" onClick={() => dispatch(setActivityWizardModalOpen(false))}>
            Cancel
          </Button>

          <Button type="primary" onClick={handleSaveActivity} loading={isCustomModulesLoading}>
            {isEditing ? 'Update' : 'Create'}
          </Button>
        </div>
      </Form>
    </Container>
  )
}

export default InputActivityForm
