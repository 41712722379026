import styled from 'styled-components'

export const Container = styled.div`
  &.version-info-card {
    height: 220px;
    width: 50%;
    background-color: ${(props) => props.theme.colors.gray1};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    overflow: auto;
    padding: 28px 30px;

    > .version-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;

      .version-item {
        display: flex;
        align-items: center;
        gap: 10px;

        .version-code {
          min-width: 40px;
          min-height: 40px;
          background-color: ${(props) => props.theme.colors.cyan2};
          color: ${(props) => props.theme.colors.cyan6};
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          text-transform: uppercase;
          border-radius: 50%;
          margin: 0px;
        }
      }

      .main-button {
        font-weight: 400;
        border-color: ${(props) => props.theme.colors.gray5};
        border-radius: 6px !important;
        padding: 10px;

        .icon {
          color: ${(props) => props.theme.colors.gray6};
          transition: 0.3s all;
        }

        &:hover {
          .icon {
            color: ${(props) => props.theme.colors.geekblue4};
          }
        }
      }
    }

    .version-overview {
      height: 90px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .title-container {
        .title {
          margin: 0px;
        }

        .description {
          color: ${(props) => props.theme.colors.gray7};
          font-size: 12px;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          margin: 0px;
        }
      }

      .extra-info {
        color: ${(props) => props.theme.colors.gray7};
        margin: 0px;
      }
    }
  }
`
