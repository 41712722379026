import { useState, useEffect, useRef } from 'react'
import 'mathlive'
import { Tooltip } from 'antd'
import { MathFormatProfessional24Regular } from '@fluentui/react-icons'
import { Modal } from './styles'

const InsertMathFormula = ({ editorRef, onChange }) => {
  const mathFieldRef = useRef()

  const [modalIsOpen, setModalOpen] = useState(false)
  const [value, setValue] = useState('')

  const handleAddMathFormula = () => {
    editorRef.current?.focus()
    editorRef.current?.insertMarkdown(`
      ‎
      $$${value}$$
      ‎
    `)
    onChange(editorRef.current?.getMarkdown())

    handleClose()
  }

  const handleClose = () => {
    setModalOpen(false)
    setValue('')
  }

  useEffect(() => {
    if (modalIsOpen) {
      // mathFieldRef.current.focus()
      return
    }
  }, [modalIsOpen])

  return (
    <>
      <Tooltip title={'Insert math formula'} arrow={false}>
        <div className="_toolbarButton_uazmk_237" onClick={() => setModalOpen(true)}>
          <MathFormatProfessional24Regular style={{ width: 18, height: 18 }} />
        </div>
      </Tooltip>

      <Modal
        className="insert-math-formula-modal"
        open={modalIsOpen}
        onCancel={handleClose}
        onOk={handleAddMathFormula}
        keyboard={false}
        maskClosable={false}
      >
        <h5>Write your math formula below:</h5>
        <div className="mathlive-container">
          <math-field ref={mathFieldRef} placeholder="f(x) = 2 + x" onInput={(evt) => setValue(evt.target.value)}>
            {value}
          </math-field>
          {/* <p>Value: {value}</p> */}
        </div>
      </Modal>
    </>
  )
}

export default InsertMathFormula
