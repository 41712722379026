import axios from 'axios'
import dayjs from 'dayjs'
import {
  SET_LAB_LOADING,
  SET_LAB_DEBUG_LOADING,
  LAB_UPDATED,
  LAB_DEBUG_UPDATED,
  LAB_SESSIONS_UPDATED,
  DEVICE_UPDATED,
  VIEW_UPDATED,
  VIEW_LOADED,
  SET_LAST_LAB_ACTION_DATE,
  SET_INACTIVITY_MODAL_OPEN,
  RESET_LABS_STATE,
  SET_LAB_PING_ERROR,
  SET_LAB_ERROR,
} from '@/store/labs'
import { MODULE_UPDATED, MODULE_PREVIEW_UPDATED } from '@/store/modules'
import { ALLOCATED_PLAYGROUNDS_UPDATED } from '@/store/playgrounds'
import {
  fetchLab,
  postLabAllocate,
  postLabDeallocate,
  postLabPing,
  postLabPullImages,
  getLabDebug,
  getSessions,
  getSession,
  postSessionStart,
  postSessionStop,
  postSessionRestart,
} from '@/services/labs'
import { showToast } from '@/utils/toast'

const checkUrlValidity = async (url, maxAttempts = 30, interval = 1000) => {
  try {
    const response = await axios.get(url)
    return true
  } catch (error) {
    if (maxAttempts === 0) {
      return false
    }

    await new Promise((resolve) => setTimeout(resolve, interval))
    return checkUrlValidity(url, maxAttempts - 1, interval)
  }
}

const getLab = (labId, config, cb) => async (dispatch) => {
  try {
    if (!config?.silentMode) {
      dispatch(SET_LAB_LOADING(true))
    }

    const lab = await fetchLab(labId)
    dispatch(LAB_UPDATED(lab))

    if (cb) {
      cb()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_LAB_ERROR(message))
  } finally {
    if (!config?.silentMode) {
      dispatch(SET_LAB_LOADING(false))
    }
  }
}

const allocateLab =
  (labId, parentType, powerOnAllDevices, config = {}, cb) =>
  async (dispatch, getState) => {
    try {
      dispatch(SET_LAB_LOADING(true))

      const { userProfile } = getState().users
      const { currentPlayground, allocatedPlaygrounds } = getState().playgrounds
      const { currentLab: lab } = getState().labs

      const data = {
        region: 'us-east',
        metadata: {
          user_id: currentPlayground?.metadata?.account_id ? currentPlayground?.user?.id : userProfile?.id,
          organization_id: userProfile?.organization?.id,
          // ...(parentType === 'module' ? { module_id: currentModule?.id } : {}),
          ...(parentType === 'playground' ? { playground_id: currentPlayground?.id } : {}),
        },
        ...config,
      }

      const labSession = await postLabAllocate(labId, data)
      const auxLab = {
        ...lab,
        allocated_session: labSession,
      }

      dispatch(LAB_UPDATED(auxLab))

      const newAllocatedLab = {
        lab_id: labSession.lab,
        lab_session_id: labSession.id,
        lab_running_time: labSession.lab_running_time,
        // ...(parentType === 'module' ? { module_id: currentModule?.id, module_name: currentModule.name } : {}),
        ...(parentType === 'playground'
          ? { playground_id: currentPlayground?.id, playground_name: currentPlayground.name }
          : {}),
      }

      // if (parentType === 'module') {
      //   const auxAllocatedModules = allocatedModules ? [...allocatedModules, newAllocatedLab] : [newAllocatedLab]
      //   dispatch(ALLOCATED_MODULES_UPDATED(auxAllocatedModules))
      // }

      if (parentType === 'playground') {
        const auxAllocatedPlaygrounds = allocatedPlaygrounds
          ? [...allocatedPlaygrounds, newAllocatedLab]
          : [newAllocatedLab]
        dispatch(ALLOCATED_PLAYGROUNDS_UPDATED(auxAllocatedPlaygrounds))
      }

      if (powerOnAllDevices) {
        auxLab.allocated_session?.devices?.forEach((ds) => {
          dispatch(startDeviceSession(labSession?.id, ds.id))
        })
      }

      if (cb) {
        cb()
      }
    } catch (error) {
      const { message } = error
      dispatch(SET_LAB_ERROR(message))
      // showToast(message, 'error')
    } finally {
      dispatch(SET_LAB_LOADING(false))
    }
  }

const deallocateLab =
  (data = {}) =>
  async (dispatch, getState) => {
    const { labSessionId, deallocateReason } = data

    try {
      dispatch(SET_LAB_LOADING(true))

      const { allocatedPlaygrounds } = getState().playgrounds
      const { currentLab: lab } = getState().labs

      await postLabDeallocate(data)

      if (lab?.allocated_session?.id === labSessionId) {
        const auxLab = {
          ...lab,
          last_deallocated_session: {
            ...lab?.allocated_session,
            status: 'deallocated',
            deallocate_reason: deallocateReason,
          },
          allocated_session: {
            ...lab?.allocated_session,
            status: 'deallocated',
            deallocate_reason: deallocateReason,
            devices: lab?.allocated_session?.devices?.map((d) => ({ ...d, status: 'stopping' })),
          },
          last_commit: lab?.commit_user_image
            ? {
                created: dayjs().toISOString(),
              }
            : null,
        }
        dispatch(LAB_UPDATED(auxLab))
      }

      const auxAllocated = allocatedPlaygrounds
        ? allocatedPlaygrounds.filter((m) => m.lab_session_id !== labSessionId)
        : []

      dispatch(ALLOCATED_PLAYGROUNDS_UPDATED(auxAllocated))
    } catch (error) {
      const { message } = error
      dispatch(SET_LAB_ERROR(message))
      // showToast(message, 'error')
    } finally {
      dispatch(SET_LAB_LOADING(false))
    }
  }

const pingLab = (labId, labSessionId, data) => async (dispatch, getState) => {
  try {
    await postLabPing(labId, labSessionId, data)
  } catch (error) {
    dispatch(SET_LAB_PING_ERROR(true))

    const { isModuleAttemptLoading } = getState().modules
    const { currentLab } = getState().labs
    const isLabAllocated = currentLab?.allocated_session?.status === 'allocated'

    if (!isModuleAttemptLoading && isLabAllocated) {
      await dispatch(getLab(labId))
    }
  }
}

const pullLabImages = (labId) => async () => {
  try {
    const response = await postLabPullImages(labId)
    showToast('Images successfully pulled')
    return response
  } catch (error) {
    return { success: false, error: error?.message }
  }
}

const fetchLabDebug = (labId, userId) => async (dispatch) => {
  try {
    dispatch(SET_LAB_DEBUG_LOADING(true))

    const response = await getLabDebug(labId, userId)
    dispatch(LAB_DEBUG_UPDATED(response))
  } catch (error) {
    const { message } = error
    dispatch(SET_LAB_ERROR(message))
  } finally {
    dispatch(SET_LAB_DEBUG_LOADING(false))
  }
}

const selectDevice = (device, view) => async (dispatch) => {
  dispatch(DEVICE_UPDATED(device))

  if (view) {
    dispatch(VIEW_UPDATED(view))
  }
}

const selectView = (view) => async (dispatch) => {
  dispatch(VIEW_UPDATED(view))
}

const setViewIsLoaded = (isLoaded) => async (dispatch) => {
  dispatch(VIEW_LOADED(isLoaded))
}

const setDeviceSessionStatus = (deviceSessionId, status, error) => (dispatch, getState) => {
  const { currentLab: lab } = getState().labs

  const auxLab = {
    ...lab,
    allocated_session: {
      ...lab?.allocated_session,
      devices: lab?.allocated_session?.devices?.map((ds) => {
        if (ds.id === deviceSessionId) {
          return {
            ...ds,
            status,
            error,
          }
        }

        return ds
      }),
    },
  }

  dispatch(LAB_UPDATED(auxLab))
}

const fetchSessions = (params) => async (dispatch) => {
  const labSessions = await getSessions(params)

  dispatch(LAB_SESSIONS_UPDATED(labSessions))
}

const fetchSession = (labSessionId, cb) => async (dispatch, getState) => {
  try {
    const { currentModule } = getState().modules
    const { currentLab } = getState().labs

    const labSession = await getSession(labSessionId)
    const isLabSessionStopping = labSession?.status === 'deallocated'
    const isDeviceStopping = labSession?.devices?.some((d) => d.status === 'stopping')
    const justStopped = isLabSessionStopping && !isDeviceStopping

    let auxLab = {
      ...currentLab,
      allocated_session: {
        ...currentLab?.allocated_session,
        status: labSession.status,
      },
      justStopped,
    }

    if (justStopped) {
      auxLab.allocated_session = null
    }

    if (auxLab.allocated_session) {
      auxLab.allocated_session.devices = auxLab.allocated_session.devices?.map((d) => {
        const auxD = { ...d }

        labSession?.devices?.forEach((newD) => {
          if (auxD?.id === newD?.id) {
            auxD.status = newD.status
          }
        })

        return auxD
      })
    }

    dispatch(LAB_UPDATED(auxLab))

    if (auxLab?.lab_type === 'module' && currentModule) {
      // update module device's preview HTML
      const previewHtmlData = labSession?.devices?.map((device) => device?.preview_html)

      let auxModule = {
        ...currentModule,
        user_status: {
          ...currentModule?.user_status,
          last_module_attempt: {
            ...currentModule?.user_status?.last_module_attempt,
            preview_html: previewHtmlData,
          },
        },
      }
      dispatch(MODULE_UPDATED(auxModule))

      const isDeviceStopped = labSession?.devices?.[0]?.status === 'stopped'

      if (isDeviceStopped) {
        dispatch(MODULE_PREVIEW_UPDATED(null))

        const previewHtmlUrl = previewHtmlData?.[0]?.preview_html
        if (previewHtmlUrl) {
          const previewHtml = await axios({
            method: 'GET',
            url: previewHtmlUrl,
          })
          dispatch(MODULE_PREVIEW_UPDATED(previewHtml?.data))
        }
      }
    }

    if (cb) {
      cb(labSession)
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_LAB_ERROR(message))
  }
}

const startDeviceSession = (labSessionId, deviceSessionId) => async (dispatch, getState) => {
  try {
    dispatch(setDeviceSessionStatus(deviceSessionId, 'starting'))
    const deviceSession = await postSessionStart(labSessionId, deviceSessionId)

    const deviceViewUrl = deviceSession?.views?.find((v) => v?.url)?.url

    const updateLab = () => {
      const { currentLab: lab } = getState().labs

      const auxLab = {
        ...lab,
        allocated_session: {
          ...lab?.allocated_session,
          devices: lab?.allocated_session?.devices.map((ds) => {
            if (ds.id === deviceSession.id) {
              return deviceSession
            }

            return ds
          }),
        },
      }

      dispatch(LAB_UPDATED(auxLab))
    }

    if (!deviceViewUrl) {
      updateLab()
      return
    }

    checkUrlValidity(deviceViewUrl).then((isValid) => {
      if (!isValid) {
        dispatch(setDeviceSessionStatus(deviceSessionId, 'error', "Device view URL isn't valid"))
        return
      }

      updateLab()
    })
  } catch (error) {
    const { message } = error
    dispatch(setDeviceSessionStatus(deviceSessionId, 'error', message))
    dispatch(SET_LAB_ERROR(message))
  }
}

const stopDeviceSession = (labSessionId, deviceSessionId) => async (dispatch, getState) => {
  try {
    dispatch(setDeviceSessionStatus(deviceSessionId, 'stopping'))
    const deviceSession = await postSessionStop(labSessionId, deviceSessionId)

    const { currentLab: lab } = getState().labs

    const auxLab = {
      ...lab,
      allocated_session: {
        ...lab?.allocated_session,
        devices: lab?.allocated_session?.devices.map((ds) => {
          if (ds.id === deviceSession.id) {
            return deviceSession
          }

          return ds
        }),
      },
    }

    dispatch(LAB_UPDATED(auxLab))
    dispatch(VIEW_UPDATED(null))
  } catch (error) {
    const { message } = error
    dispatch(setDeviceSessionStatus(deviceSessionId, 'error', message))
    dispatch(SET_LAB_ERROR(message))
  }
}

const restartDeviceSession = (labSessionId, deviceSessionId) => async (dispatch, getState) => {
  try {
    dispatch(setDeviceSessionStatus(deviceSessionId, 'restarting'))
    const deviceSession = await postSessionRestart(labSessionId, deviceSessionId)

    const deviceViewUrl = deviceSession?.views?.find((v) => v?.url)?.url

    const updateLab = () => {
      const { currentLab: lab } = getState().labs

      const auxLab = {
        ...lab,
        allocated_session: {
          ...lab?.allocated_session,
          devices: lab?.allocated_session?.devices.map((ds) => {
            if (ds.id === deviceSession.id) {
              return deviceSession
            }

            return ds
          }),
        },
      }

      dispatch(LAB_UPDATED(auxLab))
      dispatch(VIEW_UPDATED(null))
    }

    if (!deviceViewUrl) {
      updateLab()
      return
    }

    checkUrlValidity(deviceViewUrl).then((isValid) => {
      if (!isValid) {
        dispatch(setDeviceSessionStatus(deviceSessionId, 'error', "Device view URL isn't valid"))
        return
      }

      updateLab()
    })
  } catch (error) {
    const { message } = error
    dispatch(setDeviceSessionStatus(deviceSessionId, 'error', message))
    dispatch(SET_LAB_ERROR(message))
  }
}

const setLastLabActionDate = (date) => (dispatch) => {
  dispatch(SET_LAST_LAB_ACTION_DATE(date))
}

const setInactivityModalOpen = (isOpen) => (dispatch) => {
  dispatch(SET_INACTIVITY_MODAL_OPEN(isOpen))
}

const setLabsError = (message) => (dispatch) => {
  dispatch(SET_LAB_ERROR(message))
}

const resetLabsState = () => (dispatch) => {
  dispatch(RESET_LABS_STATE())
}

export {
  getLab,
  allocateLab,
  deallocateLab,
  pingLab,
  pullLabImages,
  fetchLabDebug,
  selectDevice,
  selectView,
  setViewIsLoaded,
  setDeviceSessionStatus,
  fetchSessions,
  fetchSession,
  startDeviceSession,
  stopDeviceSession,
  restartDeviceSession,
  setLastLabActionDate,
  setInactivityModalOpen,
  setLabsError,
  resetLabsState,
}
