import styled from 'styled-components'
import customProjectInfoBg from '@/assets/images/custom-modules/custom-module-info-bg.png'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  > .notification {
    background-color: ${(props) => props.theme.colors.gold2};
    color: ${(props) => props.theme.colors.gray11};
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    padding: 8px 14px;

    > .icon {
      width: 24px;
      height: 24px;
    }
  }

  > .module-card {
    height: 320px;
    background-color: ${(props) => props.theme.colors.geekblue6};
    background-image: url(${customProjectInfoBg});
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    padding: 40px;

    > .row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
    }

    .title-container {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .title {
        color: ${(props) => props.theme.colors.gray1};
        font-weight: 400;
        margin: 0px;
      }

      .description {
        max-height: 86px;
        color: ${(props) => props.theme.colors.geekblue1};
        overflow: auto;
        margin: 0px;
      }
    }

    .image-container {
      .module-image {
        min-width: 98px;
        width: 98px;
        height: auto;
        opacity: ${() => (localStorage.getItem('dw-theme') === 'dark' ? 0.7 : 1)};
        image-rendering: auto;
        pointer-events: none;
      }
    }

    .author-info {
      color: ${(props) => props.theme.colors.gray1};
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      display: flex;
      align-items: center;
    }

    .actions {
      display: flex;
      gap: 10px;

      .main-button {
        background-color: ${(props) => props.theme.colors.gray1};
        color: ${(props) => props.theme.colors.gray12};
        border: none;
        border-radius: 10px !important;

        > .icon {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
`
