import styled, { css } from 'styled-components'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  overflow: hidden;
  padding: 0px 14px;

  > .text-container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0px;

    .icon {
      height: 20px;
      width: 20px;
    }
  }

  > .actions {
    display: flex;
    align-items: center;
    gap: 10px;

    > .button {
      font-weight: 400;
      border-radius: 6px !important;
      padding: 4px 10px;

      .icon {
        height: 20px;
        width: 20px;
      }

      &.secondary-button {
        font-weight: 400;
        text-decoration: underline;
      }

      &.save-button {
        background-color: ${(props) => props.theme.colors.gray3} !important;
      }

      &.publish-button {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  ${(props) =>
    props.$mode === 'browse' &&
    css`
      background-color: ${(props) => props.theme.colors.geekblue3};
    `}

  ${(props) =>
    props.$mode === 'preview' &&
    css`
      background-color: ${(props) => props.theme.colors.purple3};
    `}

  ${(props) =>
    props.$mode === 'edit' &&
    css`
      background-color: ${(props) => props.theme.colors.cyan3};
    `}

  ${(props) =>
    props.$mode === 'amend' &&
    css`
      background-color: ${(props) => props.theme.colors.gold3};
    `}
`
