import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { Tag, Tooltip } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'
import { ArrowCircleRight24Regular, Archive24Regular } from '@fluentui/react-icons'
import { capitalize } from '@/utils/functions'
import skillTrackComingSoonImg from '@/assets/images/skill-track-coming-soon.png'
import { Container } from './styles'

const VersionHistoryDrawerItem = () => {
  const { currentCustomModuleVersion, customModuleVersions } = useSelector((state) => state.customModules)

  const hasVersions = !!customModuleVersions?.length

  const renderVersions = () => {
    if (!hasVersions) {
      return (
        <div className="no-content">
          <img className="image" src={skillTrackComingSoonImg} />

          <h5 className="text">There is no version history available.</h5>
        </div>
      )
    }

    return [...customModuleVersions].reverse()?.map((version, index) => {
      return (
        <div key={index} className="version-item">
          <h5 className="version-code">V{version?.version_number}</h5>
          <div className="version-data">
            <p className="info">
              <span className="date">{dayjs(version?.created).format('LLL')}</span>

              <Tag className={`status-tag ${version?.status}`}>
                <span className="indicator" /> {version?.status}
              </Tag>
            </p>

            <p className="description">
              Created by: {version?.author ? `${capitalize(version?.author?.name)}` : 'Anonymous'}
            </p>

            {/* <p className="description">Used in:</p> */}
          </div>

          <div className="actions">
            {currentCustomModuleVersion?.id === version?.id ? (
              <div className="is-current-version">
                <CheckCircleFilled className="icon" /> <span>Viewing this version</span>
              </div>
            ) : (
              <div className="action-buttons">
                <Tooltip title="View this version">
                  <Link className="link" to={`/projects/${version?.custom_module_id}/versions/${version?.id}`}>
                    <ArrowCircleRight24Regular className="icon" />
                  </Link>
                </Tooltip>

                {/* <Tooltip title="Archive this version">
                  <Archive24Regular className="icon" />
                </Tooltip> */}
              </div>
            )}
          </div>
        </div>
      )
    })
  }

  return (
    <Container className="version-history-drawer-item">
      <div className="content">
        <div className="versions-container">{renderVersions()}</div>
      </div>
    </Container>
  )
}

export default VersionHistoryDrawerItem
