import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import invariant from 'tiny-invariant'
import { Input, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { DeleteRegular } from '@fluentui/react-icons'
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import { attachClosestEdge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { DragHandleButton } from '@atlaskit/pragmatic-drag-and-drop-react-accessibility/drag-handle-button'
import Button from '@/components/Button'
import SectionContent from '../SectionContent'
import DropIndicator from '../DropIndicator'
import { removePage, updatePage, addPageContent, setActivityWizardModalOpen } from '@/store/customModules/actions'
import { Container } from './styles'

const Section = ({ section, isDraggable }) => {
  const sectionRef = useRef(null)
  const dispatch = useDispatch()

  const { mode, isCustomModulesLoading } = useSelector((state) => state.customModules)
  const canManageContent = mode === 'edit'
  const canEditContent = ['edit', 'amend'].includes(mode)

  const [isDragging, setIsDragging] = useState(false)
  const [closestEdge, setClosestEdge] = useState(null)

  const handleRemoveSection = () => {
    Modal.confirm({
      title: 'Remove section',
      content: 'Are you sure you want to remove this section? Content and activities within this section will be lost.',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes, remove',
      cancelText: 'Keep section',
      onOk: () => dispatch(removePage(section.id)),
      okButtonProps: {
        danger: true,
      },
    })
  }

  const handleUpdateSectionName = (newName) => {
    dispatch(updatePage(section.id, { name: newName }))
  }

  const handleAddMarkdownBlock = () => {
    const markdownBlock = {
      id: uuidv4(),
      type: 'markdown_block',
      content_md: '',
    }

    dispatch(addPageContent(section.id, markdownBlock))
  }

  useEffect(() => {
    const sectionEl = sectionRef.current
    invariant(sectionEl)

    return combine(
      draggable({
        element: sectionEl,
        getInitialData: () => ({ type: 'SECTION', id: section.id }),
        canDrag: () => canEditContent,
        onDragStart: () => setIsDragging(true),
        onDrop: () => setIsDragging(false),
      }),
      dropTargetForElements({
        element: sectionEl,
        canDrop: ({ source, self }) => canEditContent,
        getData: ({ input, element }) => {
          const data = { type: 'SECTION', id: section.id }

          return attachClosestEdge(data, {
            input,
            element,
            allowedEdges: ['top', 'bottom'],
          })
        },
        getIsSticky: () => true,
        onDragEnter: (args) => {
          if (args.source.data.type === 'SECTION' && args.source.data.id !== section.id) {
            setClosestEdge(extractClosestEdge(args.self.data))
          }
        },
        onDrag: (args) => {
          if (args.source.data.type === 'SECTION' && args.source.data.id !== section.id) {
            setClosestEdge(extractClosestEdge(args.self.data))
          }
        },
        onDragLeave: () => {
          setClosestEdge(null)
        },
        onDrop: () => {
          setClosestEdge(null)
        },
      }),
    )
  }, [section.id, canEditContent])

  return (
    <Container ref={sectionRef} className={`section  ${isDragging ? 'is-dragging' : ''}`} $readOnly={!canEditContent}>
      <div className="options-header">
        <div className="options">
          {canManageContent && (
            <>
              {isDraggable && (
                <div className="icon-container">
                  <DragHandleButton className="drag-button" label="Reorder" />
                </div>
              )}

              <div className="icon-container" onClick={handleRemoveSection}>
                <DeleteRegular className="icon" />
              </div>
            </>
          )}
        </div>

        <p className="tag">Section</p>
      </div>

      <div className="section-container">
        <div className="section-header">
          <Input
            className="section-name-input"
            value={section.name}
            placeholder="Section name"
            size="large"
            onChange={(evt) => handleUpdateSectionName(evt.target.value)}
            disabled={!canEditContent}
          />
        </div>

        {section?.content?.map((content) => (
          <SectionContent
            key={content.id}
            sectionId={section.id}
            content={content}
            canManageContent={canManageContent}
            readOnly={!canEditContent}
            isDraggable
          />
        ))}

        <div className="actions">
          <Button
            className="add-button"
            type="default"
            icon={'+'}
            onClick={handleAddMarkdownBlock}
            loading={isCustomModulesLoading}
            disabled={!canManageContent}
          >
            Add Content
          </Button>

          <Button
            className="add-button"
            type="default"
            icon={'+'}
            onClick={() => dispatch(setActivityWizardModalOpen({ sectionId: section.id }))}
            loading={isCustomModulesLoading}
            disabled={!canManageContent}
          >
            Add Activity
          </Button>
        </div>
      </div>

      {closestEdge && <DropIndicator edge={closestEdge} />}
    </Container>
  )
}

export default Section
