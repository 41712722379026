import styled from 'styled-components'
import { Modal as AntModal } from 'antd'

export const Modal = styled(AntModal)`
  max-width: 500px;
  width: 100% !important;

  .ant-modal-content {
    padding: 40px 36px 20px;
  }

  .mathlive-container {
    math-field {
      width: 100%;
      font-size: 24px;
    }
  }
`
