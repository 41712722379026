import styled from 'styled-components'

export const Container = styled.div`
  height: 2px;
  background-color: ${(props) => props.theme.colors.geekblue5};
  position: absolute;
  left: 0px;
  right: 0px;
  pointer-events: none;
  z-index: 10;

  &:before {
    content: '';
    width: 6px;
    height: 6px;
    position: absolute;
    border: 2px solid ${(props) => props.theme.colors.geekblue5};
    border-radius: 50%;
    top: -4px;
    left: -10px;
  }

  &.edge-top {
    top: 0px;
  }

  &.edge-bottom {
    bottom: -2px;
  }
`
