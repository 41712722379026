import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Tabs, Button, Tooltip } from 'antd'
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons'
import { selectView, selectDevice } from '@/store/labs/actions'
import DeviceSelector from '../DeviceSelector'
import ModuleTimer from '../ModuleTimer'
import DeviceIframe from '../DeviceIframe'
import LabLoading from '../LabLoading'
import { Container } from './styles'

const DeviceContent = ({ hideHeader, isFullScreen, setFullScreen }) => {
  const dispatch = useDispatch()

  const { userProfile } = useSelector((state) => state.users)
  const { currentModule } = useSelector((state) => state.modules)
  const { currentLab: lab, currentDevice: deviceSession, currentView } = useSelector((state) => state.labs)
  const labSession = lab?.allocated_session

  const isPro = userProfile?.is_pro || userProfile?.groups?.includes('Pro')

  const moduleAttempt = currentModule?.user_status?.last_module_attempt
  const isAttemptFinished = moduleAttempt?.status === 'finished'
  const isAttemptActive = moduleAttempt?.is_active
  const isAssessment = currentModule?.module_type === 'assessment'

  const selectedTab = deviceSession?.views?.findIndex((v) => v === currentView)

  const devices = labSession?.devices
  const allDevicesStarted = devices?.every((d) => d.status === 'started')
  const someDeviceHasError = labSession?.devices?.some((d) => d.status === 'error')

  const handleChangeView = (i) => {
    const isSameView = currentView === deviceSession?.views?.[i]

    if (isSameView) return

    dispatch(selectView(deviceSession?.views?.[i]))
  }

  useEffect(() => {
    if (!currentView && deviceSession?.views?.[0]) {
      dispatch(selectView(deviceSession?.views?.[0]))
    }
  }, [currentView, deviceSession]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (devices?.length && !deviceSession) {
      dispatch(selectDevice(devices[0]))
    }
  }, [devices?.length]) // eslint-disable-line react-hooks/exhaustive-deps

  const deviceSessionIsLoading = ['starting', 'stopping', 'restarting'].includes(deviceSession?.status)

  return (
    <Container className="device-content">
      {!hideHeader ? (
        <Tabs
          id="lab-top-panel"
          activeKey={String(selectedTab)}
          tabBarExtraContent={{
            left: <DeviceSelector disabled={deviceSession?.status !== 'started'} />,
            right: (
              <div className="actions-container">
                {!isAssessment && !isPro && currentModule?.expiration_time && !isAttemptFinished && isAttemptActive && (
                  <ModuleTimer />
                )}

                {setFullScreen && (
                  <Tooltip title={'Full screen mode'} placement="left">
                    <Button
                      onClick={setFullScreen ? () => setFullScreen(!isFullScreen) : null}
                      icon={isFullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                    />
                  </Tooltip>
                )}
              </div>
            ),
          }}
          // TODO: refactor using items instead of tabpane
          // items={[deviceSession?.views?.map((v, i) => ({ key: i, label: v.name }))]}
          onTabClick={handleChangeView}
        >
          {deviceSession?.views && deviceSession?.views.map((v, i) => <Tabs.TabPane key={i} tab={v.name} />)}
        </Tabs>
      ) : null}

      <div className="content">
        {(!allDevicesStarted || deviceSessionIsLoading) && (
          <LabLoading
            title={
              !deviceSession?.status
                ? 'Setting up environment'
                : deviceSession?.status === 'error' || someDeviceHasError
                  ? 'Device error detected'
                  : `Device is ${deviceSession?.status}`
            }
            subtitle={
              (deviceSession?.status === 'error' || someDeviceHasError) &&
              'Problem ocurred while starting the device. Please try again.'
            }
          />
        )}

        {allDevicesStarted && ['started'].includes(deviceSession?.status) && (
          <>{currentView?.url && <DeviceIframe url={currentView?.url} title={deviceSession?.name} />}</>
        )}
      </div>
    </Container>
  )
}

export default DeviceContent
