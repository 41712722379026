import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import invariant from 'tiny-invariant'
import { Modal } from 'antd'
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import { attachClosestEdge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { DragHandleButton } from '@atlaskit/pragmatic-drag-and-drop-react-accessibility/drag-handle-button'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Delete24Regular, Edit24Regular } from '@fluentui/react-icons'
import MarkdownEditor from '@/components/MarkdownEditor'
import DropIndicator from '../DropIndicator'
import { updatePageContent, removePageContent, setActivityWizardModalOpen } from '@/store/customModules/actions'
import { Container } from './styles'

const SectionContent = ({ sectionId, content, canManageContent, readOnly, isDraggable }) => {
  const dispatch = useDispatch()
  const contentRef = useRef(null)

  const { contentJson } = useSelector((state) => state.customModules)
  const sectionData = contentJson.find((page) => page.id === sectionId)
  const sectionActivities = sectionData?.content?.filter((item) => item.type !== 'markdown_block')
  const activityIndex = sectionActivities?.findIndex((item) => item.id === content.id)

  const [isDragging, setIsDragging] = useState(false)
  const [closestEdge, setClosestEdge] = useState(null)

  const isActivity = content.type !== 'markdown_block'

  const renderContent = () => {
    if (content.type === 'markdown_block') {
      return (
        <MarkdownEditor
          content={content.content_md}
          onChange={handleMarkdownBlockChange}
          hideBorder
          readOnly={readOnly}
        />
      )
    }

    if (['input', 'multiple_choice', 'code_validated'].includes(content?.type)) {
      return (
        <div className="activity-preview-container">
          <div className="activity-body">
            <span className="activity-number">{activityIndex + 1}</span>

            <div className="title-container">
              <h5 className="title">{content.title_md || 'Activity title'}</h5>
            </div>
          </div>

          <MarkdownEditor className="activity-content-markdown" content={content.content_md} readOnly />
        </div>
      )
    }
  }

  const handleMarkdownBlockChange = (newMarkdown) => {
    dispatch(updatePageContent(sectionId, content.id, { content_md: newMarkdown }))
  }

  const handleEditActivity = () => {
    dispatch(setActivityWizardModalOpen({ sectionId, activity: content }))
  }

  const handleRemoveContent = () => {
    Modal.confirm({
      title: 'Remove content',
      content: 'Are you sure you want to remove this content? This action cannot be undone.',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes, remove',
      cancelText: 'Keep content',
      onOk: () => dispatch(removePageContent(sectionId, content.id)),
      okButtonProps: {
        danger: true,
      },
    })
  }

  useEffect(() => {
    const contentEl = contentRef.current
    invariant(contentEl)

    return combine(
      draggable({
        element: contentEl,
        getInitialData: () => ({ type: 'CONTENT', id: content.id, sectionId }),
        canDrag: () => !readOnly,
        onDragStart: () => setIsDragging(true),
        onDrop: () => setIsDragging(false),
      }),
      dropTargetForElements({
        element: contentEl,
        canDrop: ({ source, self }) => !readOnly,
        getData: ({ input, element }) => {
          const data = { type: 'CONTENT', id: content.id, sectionId }

          return attachClosestEdge(data, {
            input,
            element,
            allowedEdges: ['top', 'bottom'],
          })
        },
        getIsSticky: () => true,
        onDragEnter: (args) => {
          if (args.source.data.type === 'CONTENT' && args.source.data.id !== content.id) {
            setClosestEdge(extractClosestEdge(args.self.data))
          }
        },
        onDrag: (args) => {
          if (args.source.data.type === 'CONTENT' && args.source.data.id !== content.id) {
            setClosestEdge(extractClosestEdge(args.self.data))
          }
        },
        onDragLeave: () => {
          setClosestEdge(null)
        },
        onDrop: () => {
          setClosestEdge(null)
        },
      }),
    )
  }, [content.id, readOnly])

  return (
    <Container ref={contentRef} className={`section-content ${isDragging ? 'is-dragging' : ''}`} $readOnly={readOnly}>
      <div className="options-header">
        <div className="options">
          {!readOnly && (
            <>
              {isDraggable && canManageContent && (
                <div className="icon-container">
                  <DragHandleButton className="drag-button" label="Reorder" />
                </div>
              )}

              {isActivity && (
                <div className="icon-container" onClick={() => handleEditActivity()}>
                  <Edit24Regular className="icon" />
                </div>
              )}

              {canManageContent && (
                <div className="icon-container" onClick={handleRemoveContent}>
                  <Delete24Regular className="icon" />
                </div>
              )}
            </>
          )}
        </div>

        <p className="tag">{isActivity ? 'Activity' : 'Content'}</p>
      </div>

      <div className="content-container">{renderContent()}</div>

      {closestEdge && <DropIndicator edge={closestEdge} />}
    </Container>
  )
}

export default SectionContent
