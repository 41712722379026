import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'
import {
  ArrowCircleUpRight24Regular,
  Warning24Regular,
  Add24Regular,
  HatGraduation24Regular,
} from '@fluentui/react-icons'
import { capitalize } from '@/utils/functions'
import { showToast } from '@/utils/toast'
import { renderModuleImage } from '@/helpers/renderModuleImage'
import Button from '@/components/Button'
import {
  createCustomModuleVersion,
  createCustomModuleVersionEdit,
  setCustomModuleMode,
} from '@/store/customModules/actions'
import ProfilePicture from '@/components/ProfilePicture'
import { Container } from './styles'

const CustomModuleInfoCard = () => {
  const dispatch = useDispatch()

  const { userProfile } = useSelector((state) => state.users)
  const { customModuleVersions, currentCustomModuleVersion, mode, isCustomModulesLoading } = useSelector(
    (state) => state.customModules,
  )

  const isLastVersion = customModuleVersions?.[customModuleVersions?.length - 1]?.id === currentCustomModuleVersion?.id

  const otherIsEditing =
    currentCustomModuleVersion?.allocated_by && currentCustomModuleVersion?.allocated_by?.id !== userProfile?.id

  const handleStartEditing = () => {
    dispatch(
      createCustomModuleVersionEdit(
        currentCustomModuleVersion?.custom_module_id,
        currentCustomModuleVersion?.id,
        () => dispatch(setCustomModuleMode('edit')),
        (message) => showToast(message, 'error'),
      ),
    )
  }

  const handleCreateNewVersion = () => {
    dispatch(
      createCustomModuleVersion(
        currentCustomModuleVersion?.custom_module_id,
        (newCustomModuleVersion) => showToast('New version created'),
        (message) => showToast(message, 'error'),
      ),
    )
  }

  return (
    <Container className="custom-module-info-card">
      {otherIsEditing && (
        <div className="notification">
          <Warning24Regular className="icon" />
          Project is currently being edited by {currentCustomModuleVersion?.allocated_by?.name}
        </div>
      )}

      <div className="module-card">
        <div className="row">
          <div className="title-container">
            <h3 className="title">{currentCustomModuleVersion?.name}</h3>

            {currentCustomModuleVersion?.description && (
              <p className="description">{currentCustomModuleVersion?.description} </p>
            )}
          </div>

          <div className="image-container">{renderModuleImage(currentCustomModuleVersion)}</div>
        </div>

        <div className="row">
          <span className="author-info">
            <ProfilePicture
              src={currentCustomModuleVersion?.author?.avatar_url}
              alt={capitalize(currentCustomModuleVersion?.author?.name || 'Anonymous')}
              size="small"
              hideBadge
              disableTooltip
            />

            {currentCustomModuleVersion?.author?.name
              ? `${capitalize(currentCustomModuleVersion?.author?.name)}`
              : 'Anonymous'}
          </span>

          <div className="actions">
            {mode === 'browse' && (
              <Button
                className="main-button"
                type="primary"
                size="large"
                onClick={handleStartEditing}
                loading={isCustomModulesLoading}
                disabled={currentCustomModuleVersion?.allocated_by}
              >
                Start editing <ArrowCircleUpRight24Regular className="icon" />
              </Button>
            )}

            {['preview', 'amend'].includes(mode) && (
              <>
                <Tooltip
                  title={
                    !isLastVersion
                      ? 'You can only create a new version if you are viewing the latest version'
                      : mode === 'amend'
                        ? "You can't create a new version while amending"
                        : ''
                  }
                >
                  <Button
                    className="main-button"
                    type="primary"
                    size="large"
                    onClick={handleCreateNewVersion}
                    disabled={mode === 'amend' || !isLastVersion}
                    loading={isCustomModulesLoading}
                  >
                    <Add24Regular className="icon" /> Create new version
                  </Button>
                </Tooltip>

                <Link to={`/project/${currentCustomModuleVersion?.id}`} target="_blank">
                  <Button className="main-button" type="primary" size="large" disabled={mode === 'amend'}>
                    <HatGraduation24Regular className="icon" /> Preview as student
                  </Button>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </Container>
  )
}

export default CustomModuleInfoCard
