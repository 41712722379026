import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { Tag } from 'antd'
import { ArrowCircleUpRight24Regular } from '@fluentui/react-icons'
import { capitalize } from '@/utils/functions'
import Button from '@/components/Button'
import { selectInfoSideDrawerItem } from '@/store/customModules/actions'
import { Container } from './styles'

const VersionCard = () => {
  const dispatch = useDispatch()

  const { currentCustomModuleVersion } = useSelector((state) => state.customModules)

  return (
    <Container className="version-info-card">
      <div className="version-content">
        <div className="version-item">
          <h5 className="version-code">V{currentCustomModuleVersion?.version_number || '1'}</h5>

          <Tag className={`status-tag ${currentCustomModuleVersion?.status}`}>
            <span className="indicator" /> {currentCustomModuleVersion?.status}
          </Tag>
        </div>

        <Button
          className="main-button"
          type="default"
          onClick={() => dispatch(selectInfoSideDrawerItem('version-history'))}
        >
          View versions <ArrowCircleUpRight24Regular className="icon" />
        </Button>
      </div>

      <div className="version-overview">
        <div className="title-container">
          <h5 className="title">{dayjs(currentCustomModuleVersion?.created).format('lll')}</h5>
          <p className="description">
            Created by: {capitalize(currentCustomModuleVersion?.author?.name || 'Anonymous')}
          </p>
        </div>

        {/* <p className="extra-info">Used in {currentCustomModuleVersion?.cohorts?.length} cohorts</p> */}
      </div>
    </Container>
  )
}

export default VersionCard
