import { Container } from './styles'

const DropIndicator = ({ edge }) => {
  const edgeClassMap = {
    top: 'edge-top',
    bottom: 'edge-bottom',
  }

  const edgeClass = edgeClassMap[edge]

  return <Container className={`drop-indicator ${edgeClass}`}></Container>
}

export default DropIndicator
