import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.gray1};
  display: flex;
  flex-direction: column;

  > .content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    overflow: auto;
    padding: 24px;

    > .card {
      background-color: ${(props) => props.theme.colors.gray1};
      display: flex;
      flex-direction: column;
      gap: 20px;
      border: 1px solid ${(props) => props.theme.colors.gray4};
      border-radius: 10px;
      padding: 28px 30px;
    }

    .stack-card {
      .image-container {
        .image {
          height: 28px;
        }
      }

      .title-container {
        display: flex;
        flex-direction: column;
        gap: 6px;

        > .title {
          margin: 0px;
        }

        .description,
        .description > p {
          color: ${(props) => props.theme.colors.gray7};
          font-size: 12px;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          margin: 0px;
        }
      }
    }

    .box-size-card {
      > .header {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .image-container {
          height: 26px;
          width: 36px;
          background-color: ${(props) => props.theme.colors.geekblue1};
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 7px;
          margin-top: 6px;

          > svg {
            height: 20px;
            width: 20px;
            color: ${(props) => props.theme.colors.geekblue5};
          }
        }

        .name {
          margin: 0px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      .value-boxes-container {
        display: flex;
        flex-direction: column;
        gap: 9px;

        .value-box {
          background-color: ${(props) => props.theme.colors.gray3};
          display: flex;
          justify-content: space-between;
          gap: 10px;
          border-radius: 4px;
          padding: 4px 6px;

          .key {
            color: ${(props) => props.theme.colors.gray7};
            display: flex;
            gap: 5px;
            align-items: center;
            font-size: 12px;
            line-height: 20px;
            margin: 0px;

            .icon {
              height: 20px;
              width: 20px;
              color: ${(props) => props.theme.colors.gray7};
            }
          }

          .value {
            font-size: 12px;
            font-weight: 700;
            line-height: 20px;
            margin: 0px;
          }
        }
      }
    }
  }
`
