import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { Skeleton } from 'antd'
import { AddCircle24Regular } from '@fluentui/react-icons'
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index'
import { reorder } from '@atlaskit/pragmatic-drag-and-drop/reorder'
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { isDataWarsHostName, teamsURL } from '@/helpers/env'
import { showToast } from '@/utils/toast'
import Layout1 from '@/layouts/Layout1'
import FadeIn from '@/components/FadeIn'
import Button from '@/components/Button'
import SecondaryNavbar from './components/SecondaryNavbar'
import SiderMenu from './components/SiderMenu'
import Section from './components/Section'
import ErrorCard from '@/pages/ErrorPage/components/ErrorCard'
import InactivityModal from '@/pages/ModulePage/components/InactivityModal'
import ActivityWizardModal from './components/ActivityWizardModal'
import PublishModal from './components/PublishModal'
import {
  fetchCustomModuleVersion,
  fetchCustomModuleVersions,
  setCustomModuleMode,
  selectInfoSideDrawerItem,
  addPage,
  updatePages,
  resetCustomModulesState,
} from '@/store/customModules/actions'
import { getLab, startDeviceSession, resetLabsState } from '@/store/labs/actions'
import DeviceContent from '@/pages/ModulePage/components/DeviceContent'
import DescriptionDrawerItem from './components/DescriptionDrawerItem'
import VersionHistoryDrawerItem from './components/VersionHistoryDrawerItem'
import CustomModuleInfoCard from './components/CustomModuleInfoCard'
import StackCard from './components/StackCard'
import VersionCard from './components/VersionCard'
import EmptyState from '@/assets/images/custom-modules/custom-module-empty-state.svg?react'
import { MainContainer, SecondaryContainer } from './styles'

const CustomProjectVersionPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { customModuleId, versionId } = useParams()

  const { userProfile, isAuthenticated } = useSelector((state) => state.users)
  const { currentAccount } = useSelector((state) => state.accounts)
  const {
    currentCustomModuleVersion,
    contentJson,
    infoSideDrawer,
    mode,
    isCustomModulesLoading,
    error: customModulesError,
  } = useSelector((state) => state.customModules)
  const { currentLab, isLabLoading } = useSelector((state) => state.labs)

  const hasLab = !!currentCustomModuleVersion?.lab_id
  const isPublished = currentCustomModuleVersion?.status === 'published'
  const isEditing =
    currentCustomModuleVersion?.allocated_by && currentCustomModuleVersion?.allocated_by?.id === userProfile?.id
  const canAddContent = mode === 'edit'

  const [isFullScreen, setFullScreen] = useState(false)
  const [instanceId, setInstanceId] = useState()

  const handleCustomModuleVersionLoad = async (customModuleId, versionId) => {
    await dispatch(fetchCustomModuleVersion(customModuleId, versionId))
  }

  const handleLoadVersions = async () => {
    await dispatch(fetchCustomModuleVersions(customModuleId))
  }

  const renderMainContent = () => {
    if ((!currentCustomModuleVersion || currentCustomModuleVersion?.justCreated) && !customModulesError) {
      return (
        <div className="loading-container">
          <Skeleton active paragraph={{ rows: 4 }} />
          <Skeleton active title={false} paragraph={{ rows: 6 }} />
          <Skeleton active title={false} paragraph={{ rows: 2 }} />
          <Skeleton active paragraph={{ rows: 7 }} />
          <Skeleton active paragraph={{ rows: 6 }} />
        </div>
      )
    }

    if (customModulesError) {
      return (
        <div className="error-card-container">
          <ErrorCard
            title={
              customModulesError === 'Not found.' ? 'Project not found' : 'There was an error loading this project'
            }
            primaryButtonText="Maybe go to admin platform?"
            primaryButtonLinkTo={() => {
              const getTeamsUrl = () => {
                if (isDataWarsHostName) {
                  return teamsURL
                }

                // namespace
                return `${teamsURL}accounts/${currentAccount?.id}`
              }

              navigate(getTeamsUrl())
            }}
          />
        </div>
      )
    }

    if (infoSideDrawer === 'content' || !infoSideDrawer) {
      return (
        <>
          {contentJson?.length ? (
            renderSections()
          ) : (
            <div className="empty-state-container">
              <div className="content">
                <EmptyState className="image" />
                <h4 className="title">Unleash your creativity!</h4>
                <p className="text">
                  Add sections, content blocks, and exciting activities with just a few clicks.
                  <br />
                  Click below to get started!
                </p>
              </div>
            </div>
          )}

          <div className="actions">
            <Button
              className="add-button"
              type="default"
              icon={<AddCircle24Regular className="icon" />}
              onClick={() => handleAddPage()}
              disabled={!canAddContent}
              loading={isCustomModulesLoading}
            >
              Add Section
            </Button>
          </div>

          <ActivityWizardModal />
        </>
      )
    }

    if (infoSideDrawer === 'description') {
      return <DescriptionDrawerItem instanceId={instanceId} setInstanceId={setInstanceId} />
    }

    if (infoSideDrawer === 'version-history') {
      return <VersionHistoryDrawerItem />
    }
  }

  const handleAddPage = (predefinedContent) => {
    const emptyPage = {
      id: uuidv4(),
      type: 'page',
      name: `Section ${(contentJson?.length || 0) + 1}`,
      content: predefinedContent || [],
    }

    dispatch(addPage(emptyPage))
  }

  const renderSections = () => {
    return contentJson?.map((page) => {
      return <Section key={page.id} section={page} isDraggable />
    })
  }

  const updateById = (list, id, updatedData) => {
    return list.map((item) => (item.id === id ? { ...item, ...updatedData } : item))
  }

  const reorderSections = useCallback(
    ({ startIndex, finishIndex }) => {
      const updatedSections = reorder({
        list: contentJson,
        startIndex,
        finishIndex,
      })

      dispatch(updatePages(updatedSections))
    },
    [contentJson],
  )

  const reorderContent = useCallback(
    ({ sectionId, startIndex, finishIndex }) => {
      const sourceSectionData = contentJson.find((item) => item.id === sectionId)

      const updatedContent = reorder({
        list: sourceSectionData.content,
        startIndex,
        finishIndex,
      })

      const updatedSection = {
        ...sourceSectionData,
        content: updatedContent,
      }

      dispatch(updatePages(updateById(contentJson, sectionId, updatedSection)))
    },
    [contentJson],
  )

  const moveContent = useCallback(
    ({
      sourceSectionId,
      movedContentIndexInSourceSection,
      destinationSectionId,
      movedContentIndexInDestinationSection,
    }) => {
      const sourceSectionData = contentJson.find((item) => item.id === sourceSectionId)
      const destinationSectionData = contentJson.find((item) => item.id === destinationSectionId)

      const contentToMove = sourceSectionData.content[movedContentIndexInSourceSection]

      const newSourceSectionData = {
        ...sourceSectionData,
        content: sourceSectionData.content.filter((c) => c.id !== contentToMove.id),
      }

      const newDestinationContent = Array.from(destinationSectionData.content)
      const newIndexInDestination = movedContentIndexInDestinationSection ?? 0
      newDestinationContent.splice(newIndexInDestination, 0, contentToMove)

      const newDestinationSectionData = {
        ...destinationSectionData,
        content: newDestinationContent,
      }

      let auxSections = updateById(contentJson, sourceSectionId, newSourceSectionData)
      auxSections = updateById(auxSections, destinationSectionId, newDestinationSectionData)

      dispatch(updatePages(auxSections))
    },
    [contentJson],
  )

  const handleDrop = useCallback(
    ({ source, location }) => {
      const destination = location.current.dropTargets.length
      if (!destination) {
        return
      }

      if (source.data.type === 'SECTION') {
        const sourceSectionId = source.data.id
        const sourceSectionIndex = contentJson.findIndex((item) => item.id === sourceSectionId)

        const destinationSectionRecord = location.current.dropTargets[location.current.dropTargets.length - 1]
        const destinationSectionId = destinationSectionRecord.data.id
        const destinationSectionIndex = contentJson.findIndex((item) => item.id === destinationSectionId)

        if (sourceSectionId === destinationSectionId) {
          return
        }

        const closestEdgeOfTarget = extractClosestEdge(destinationSectionRecord.data)

        const finalDestinationSectionIndex = getReorderDestinationIndex({
          startIndex: sourceSectionIndex,
          indexOfTarget: destinationSectionIndex,
          closestEdgeOfTarget,
          axis: 'vertical',
        })

        reorderSections({
          startIndex: sourceSectionIndex,
          finishIndex: finalDestinationSectionIndex,
        })
      }

      if (source.data.type === 'CONTENT') {
        const sourceContentId = source.data.id
        const [sourceContentRecord, sourceSectionRecord] = location.initial.dropTargets

        const sourceSectionId = sourceSectionRecord.data.id
        const sourceSectionData = contentJson.find((item) => item.id === sourceSectionId)

        const sourceContentIndex = sourceSectionData.content.findIndex((item) => item.id === sourceContentId)

        if (location.current.dropTargets.length === 1) {
          const [destinationSectionRecord] = location.current.dropTargets
          const destinationSectionId = destinationSectionRecord.data.id

          // same section
          if (sourceSectionId === destinationSectionId) {
            const finalDestinationContentIndex = getReorderDestinationIndex({
              startIndex: sourceContentIndex,
              indexOfTarget: sourceSectionData.content.length - 1,
              closestEdgeOfTarget: null,
              axis: 'vertical',
            })

            reorderContent({
              columnId: sourceSectionData.id,
              startIndex: sourceContentIndex,
              finishIndex: finalDestinationContentIndex,
            })
            return
          }

          // other section
          moveContent({
            sourceSectionId,
            movedContentIndexInSourceSection: sourceContentIndex,
            destinationSectionId,
          })
          return
        }

        if (location.current.dropTargets.length === 2) {
          const [destinationContentRecord, destinationSectionRecord] = location.current.dropTargets

          const destinationSectionId = destinationSectionRecord.data.id
          const destinationSectionData = contentJson.find((item) => item.id === destinationSectionId)

          const destinationContentIndex = destinationSectionData.content.findIndex(
            (item) => item.id === destinationContentRecord.data.id,
          )

          const closestEdgeOfTarget = extractClosestEdge(destinationContentRecord.data)

          // same section
          if (sourceSectionId === destinationSectionId) {
            // When you are rendering drop indicators (eg lines) between items in a list,
            // it can be difficult to know what the index the dropped item should go into.
            // The final index will depend on what the closest Edge is.
            // getReorderDestinationIndex can give you the final index for a reordering operation,
            // taking into account which Edge is closest.
            const finalDestinationContentIndex = getReorderDestinationIndex({
              startIndex: sourceContentIndex,
              indexOfTarget: destinationContentIndex,
              closestEdgeOfTarget,
              axis: 'vertical',
            })

            reorderContent({
              sectionId: sourceSectionId,
              startIndex: sourceContentIndex,
              finishIndex: finalDestinationContentIndex,
            })
            return
          }

          const finalDestinationContentIndex =
            closestEdgeOfTarget === 'bottom' ? destinationContentIndex + 1 : destinationContentIndex

          // other section
          moveContent({
            sourceSectionId,
            movedContentIndexInSourceSection: sourceContentIndex,
            destinationSectionId,
            movedContentIndexInDestinationSection: finalDestinationContentIndex,
          })
          return
        }
      }
    },
    [contentJson, reorderContent, moveContent],
  )

  useEffect(() => {
    return monitorForElements({
      onDrop: handleDrop,
    })
  }, [handleDrop])

  useEffect(() => {
    if (!currentLab?.allocated_session || !isEditing || isPublished) return

    currentLab?.allocated_session?.devices?.forEach((ds) => {
      if (ds.status === 'stopped') {
        dispatch(startDeviceSession(currentLab?.allocated_session?.id, ds.id))
      }
    })
  }, [currentLab?.allocated_session])

  useEffect(() => {
    if (!currentCustomModuleVersion) return

    if (currentCustomModuleVersion?.id !== versionId) {
      navigate(`/projects/${customModuleId}/versions/${currentCustomModuleVersion?.id}`)
    }

    if (isEditing) {
      dispatch(setCustomModuleMode(isPublished ? 'amend' : 'edit'))
    } else {
      dispatch(setCustomModuleMode(isPublished ? 'preview' : 'browse'))
    }

    dispatch(selectInfoSideDrawerItem('content'))

    if (isEditing && !isPublished && currentCustomModuleVersion?.lab_id && !currentLab) {
      dispatch(getLab(currentCustomModuleVersion?.lab_id))
    }
  }, [currentCustomModuleVersion])

  useEffect(() => {
    if (!customModuleId) return

    handleLoadVersions()
  }, [customModuleId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!customModuleId || !versionId) return

    dispatch(resetLabsState())
    handleCustomModuleVersionLoad(customModuleId, versionId)

    // TODO: is this working or needs another useEffect?
    if (customModulesError) {
      showToast(
        customModulesError === 'Not found.' ? 'Project not found' : 'There was an error loading this project',
        'error',
      )
    }
  }, [customModuleId, versionId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const isAccountOwner = isDataWarsHostName
      ? userProfile?.accounts?.some((a) => a.role === 'owner')
      : userProfile?.accounts?.find((account) => account.id === currentAccount?.id)?.role === 'owner'

    if (!isAccountOwner) {
      return navigate('/', { replace: true })
    }

    return () => {
      dispatch(resetCustomModulesState())
      dispatch(resetLabsState())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout1
      isLoading={isAuthenticated === null}
      navbar
      siderContent={!customModulesError && <SiderMenu />}
      disableSiderCollapse
      secondaryNavbarContent={!customModulesError && <SecondaryNavbar />}
      resizable
      isFullScreen={isFullScreen}
      mainContent={
        <MainContainer className="projects-creation-page-main-container" $isEmptyState={!contentJson?.length}>
          {renderMainContent()}

          {isAuthenticated && (
            <>
              <InactivityModal />
              <PublishModal />
            </>
          )}
        </MainContainer>
      }
      secondaryContent={
        !customModulesError && (
          <SecondaryContainer className="projects-creation-page-secondary-container">
            {!!currentLab ? (
              <DeviceContent isFullScreen={isFullScreen} setFullScreen={setFullScreen} />
            ) : (
              <FadeIn className="content">
                {(!currentCustomModuleVersion && !customModulesError) ||
                (mode === 'edit' && currentCustomModuleVersion?.status === 'draft' && hasLab && !currentLab) ? (
                  <>
                    <Skeleton className="main-loading-card" active paragraph={{ rows: 1 }} title={false} />

                    <div className="row">
                      <Skeleton className="secondary-loading-card" active paragraph={{ rows: 1 }} title={false} />
                      <Skeleton className="secondary-loading-card" active paragraph={{ rows: 1 }} title={false} />
                    </div>
                  </>
                ) : (
                  <>
                    <CustomModuleInfoCard />

                    <div className="row">
                      <StackCard />
                      <VersionCard />
                    </div>
                  </>
                )}
              </FadeIn>
            )}
          </SecondaryContainer>
        )
      }
    />
  )
}

export default CustomProjectVersionPage
